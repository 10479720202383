import React,{ Fragment,useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Parser from 'html-react-parser';
import { Link } from "react-router-dom";
import Select from 'react-select';
import {useAuthValue} from './AuthContext';
import { getDistance} from 'geolib';
import store from '../../app/store';
import { useDispatch,useSelector } from 'react-redux';
import{fetcharea,arealist,currentlatitude,currentlongitude} from '../../features/counter/apiSlice';
import { registration, booking, city_check,fetchDataWithRetry } from "../../helper/formdata";
export const Hospital = (props) => {
  //console.log(props);
  const [hospital, setHospital] = useState([]);
  const [hospital1, setHospital1] = useState([]);
  const [selected1, setSelected1] = useState([]);
  const [error, setError] = useState(false);
  const areaslistt = useSelector(arealist);
  const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude);
  //console.log(areaslistt);
  const dispatch=useDispatch();

  const search = () => {
    //console.log(selected1.label);
    //console.log(clinic.filter((item) => selected === item.address2));
    if (selected1 !== null) {
      setHospital(hospital1.filter((item) => item.address2 === selected1.label));
      //setSelected(selected);
    }
    
    //console.log(selected);
  }; 
	
    useEffect(() => {
      fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/hospital`).then((hospital) => {
        setHospital(hospital.data);
    
      }).catch((error) => {
     setError(error);
     });

      //fetchWithNetworkCheck(`${process.env.REACT_APP_BASE_URL}/hospital`);
      // const data = localStorage.getItem('hospital');
      // if (data) {
      //   setHospital(JSON.parse(data));
      // }
      
      // fetchData1();
     
    }, []);
    const handleOnChange1 = value1 => {
      //console.log(value1);
      if (value1 === null) {
        value1 = [];
      }
      else
      {
          setSelected1(value1);
      }
      //console.log(selected1);
    };
    const openLinkInNewTab = ( url ) => {
      const newTab = window.open(url, '_blank', 'noopener,noreferrer');
      if ( newTab ) newTab.opener = null;
    }
  // const fetchData1 = async(ids) => {
   
  //   await fetch(`${process.env.REACT_APP_BASE_URL}/hospital`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data1 => {
  //       localStorage.setItem('hospital', JSON.stringify(data1.data));
  //       setHospital(data1.data)
	// 	//console.log(category);
  //     })
	// }
	  
  return (
    <div>
	<Header />
  <div>
  <div className="banner">
<div className="banner-item banner-bg inner_banner">            
        </div>
        </div>
        <div>     
        <div className="intro">
	    <div className="container" style={{ marginTop: `24px` }}>     
      
      <div className="row">
        <div className="col-md-12">
          <div className="intro-banner-search-form margin-top-95" style={{marginTop: '32%'}}>   
            
            <div className="intro-search-field with-autocomplete">
              <div className="input-with-icon">
			  <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"       
        labelledBy="Select City" 
        onChange={handleOnChange1}
        value={selected1} 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
                <i className=""></i> </div>
            </div>          
            
           
            <div className="intro-search-button">
			<button type="submit" onClick={search} className="button ripple-effect">Search</button>
            </div>
          </div>
        </div>
      </div>
      
      </div>
            </div>
    </div>
        <section className="clinic-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-10 col-md-12 col-sm-12 content-side">
                <div className="item-shorting clearfix">
                  
                  <div className="right-column pull-right clearfix">                    
                    <div className="menu-box">
                      <button className="list-view on"><i className="icon-List" /></button>
                      <button className="grid-view"><i className="icon-Grid" /></button>
                    </div>
                  </div>
                </div>
                <div className="wrapper list">
                  <div className="clinic-list-content list-item">                   
                    {typeof hospital != 'undefined' && hospital.length > 0 && hospital && (
						<>
						{hospital.map((item) => {
				   const { id, hospital_image, box_title,box_description,address1,address2,lat1,long1 } = item;
           if(lat1 !=null && long1!=null && lt!=null && lng!=null)
           {
           var dis=getDistance(               
            { latitude: lat1, longitude: long1 },
            { latitude: lt, longitude: lng }
               );
              }
              else
              {
                var dis=0;
              }
           var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + lt + "," + lng;
var destination = "&destination=" + lat1 + "," + long1;
var newUrl = new URL(url + origin + destination);
				   return (
				   <div key={id}>
                    <div className="clinic-block-one">
                      <div className="inner-box">
                        <div className="pattern">
                          <div className="pattern-1" style={{backgroundImage: 'url(assets/img/shape/shape-24.png)'}} />
                          <div className="pattern-2" style={{backgroundImage: 'url(assets/img/shape/shape-25.png)'}} />
                        </div>
                        <figure className="image-box" style={{width:'32%'}}><img src={hospital_image} alt={box_title} /></figure>
                        <div className="content-box" style={{marginLeft:'200px'}}>
                          <div className="like-box"><a href="clinic-details.html"><i className="fa fa-heart" /></a></div>
                          <ul className="name-box clearfix">
                            <li className="name"><h3><a href="clinic-details.html">{box_title}</a></h3></li>
                            <li><i className="icon-Trust-1" /></li>
                            <li><i className="icon-Trust-2" /></li>
                          </ul>
                          <span className="designation">Best healthcare center</span>
                          <div className="text">
                          
                          {Parser(box_description)}
                          </div>
                          <div className="rating-box clearfix">
                            <ul className="rating clearfix">
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><a href="clinic-details.html">(21)</a></li>
                            </ul>
                            <div className="link"><a href="clinic-details.html">24/7 Available</a></div>
                          </div>
                          <div className="location-box">
                          <p><i className="fa fa-map-marker">{address1}<br></br>{address2}</i></p>
                          <p><i className="fa fa-map-marker">{`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}</i></p>
   <button onClick={ () => openLinkInNewTab(newUrl)}> Direction </button>
                          </div>
                          <div className="btn-box"> <Link to="/doctor"  state={{ids: id,type:'hospital'}}> View Doctor </Link></div>
                        </div>
                      </div>
                    </div>
                    </div>
						);
          })}
						</>
						)}
                  </div>                  
                </div>                
              </div>              
            </div>
          </div>
        </section>
       
      </div>
	   <Footer />
    </div>
  );
};
