import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import Select from 'react-select';
import {useAuthValue} from './AuthContext';
import { getDistance} from 'geolib';
export const Clinic = () => {  
  const [clinic, setClinic] = useState(localStorage.getItem('clinic') ? JSON.parse(localStorage.getItem('clinic')) : []);
  const [clinic1, setClinic1] = useState(localStorage.getItem('clinic') ? JSON.parse(localStorage.getItem('clinic')) : []);
  const [center, setCenter] = useState([]);
  const [selected, setSelected] = useState([]);
  const {lt,lng} = useAuthValue() 
  const options = [
    { value: 'Burari', label: 'Burari' },
    { value: 'Timarpur', label: 'Timarpur' },
    { value: 'Chandni Chowk', label: 'Chandni Chowk' },
    { value: 'Model Town', label: 'Model Town' },
    { value: 'Sadar Bazar', label: 'Sadar Bazar' },
    { value: 'Ballimaran', label: 'Ballimaran' },
    { value: 'Karol Bagh', label: 'Karol Bagh' },
    { value: 'Trinagar', label: 'Trinagar' },
    { value: 'Matia Mahal', label: 'Matia Mahal' },
    { value: 'Trilokpuri', label: 'Trilokpuri' },
    { value: 'Kondli', label: 'Kondli' },
    { value: 'Patparganj', label: 'Patparganj' },
    { value: 'Laxmi Nagar', label: 'Laxmi Nagar' },
    { value: 'Vishwas Nagar', label: 'Vishwas Nagar' },
    { value: 'Krishna Nagar', label: 'Krishna Nagar' },
    { value: 'Gandhi Nagar', label: 'Gandhi Nagar' },
    { value: 'Bijwasan', label: 'Bijwasan' },
    { value: 'Matia Mahal', label: 'Matia Mahal' },
    { value: 'Delhi Cantt', label: 'Delhi Cantt' },
    { value: 'Rajinder Nagar', label: 'Rajinder Nagar' },
    { value: 'New Delhi', label: 'New Delhi' },
    { value: 'RK Puram', label: 'RK Puram' },
    { value: 'Mehrauli', label: 'Mehrauli' },
    { value: 'Chattarpur', label: 'Chattarpur' },
    { value: 'Janakpuri', label: 'Janakpuri' },
    { value: 'Hari nagar', label: 'Hari nagar' },
    { value: 'Narela', label: 'Narela' },
    { value: 'Adarsh Nagar', label: 'Adarsh Nagar' },
    { value: 'Badli', label: 'Badli' },
    { value: 'Bawana', label: 'Bawana' },
    { value: 'North', label: 'North' },
    { value: 'Rithala', label: 'Rithala' },
    { value: 'Vikas Puri', label: 'Vikas Puri' },
    { value: 'Seelampur', label: 'Seelampur' },
    { value: 'Gokalpur', label: 'Gokalpur' },
    { value: 'Mustafabad', label: 'Mustafabad' },
    { value: 'Karawal Nagar', label: 'Karawal Nagar' },
    { value: 'Badarpur Khadar', label: 'Badarpur Khadar' },
    { value: 'Mundka', label: 'Mundka' },
    { value: 'Kirari', label: 'Kirari' },
    { value: 'Sultanpur Majra', label: 'Sultanpur Majra' },
    { value: 'Mangolpuri', label: 'Mangolpuri' },
    { value: 'Rohini', label: 'Rohini' },
    { value: 'Shalimar Bagh', label: 'Shalimar Bagh' },
    { value: 'Shakur Basti', label: 'Shakur Basti' },
    { value: 'Wazirpur', label: 'Wazirpur' },
    { value: 'Seelampur', label: 'Seelampur' },
    { value: 'Shahdara', label: 'Shahdara' },
    { value: 'Seemapuri', label: 'Seemapuri' },
    { value: 'Rohtash Nagar', label: 'Rohtash Nagar' },
    { value: 'Babarpur', label: 'Babarpur' },
    { value: 'Malviya Nagar', label: 'Malviya Nagar' },
    { value: 'Deoli', label: 'Deoli' },
    { value: 'Ambedkar Nagar', label: 'Ambedkar Nagar' },
    { value: 'Greater Kailash', label: 'Greater Kailash' },
    { value: 'Jangpura', label: 'Jangpura' },
    { value: 'Kasturba Nagar', label: 'Kasturba Nagar' },
    { value: 'Sangam Vihar', label: 'Sangam Vihar' }, 
    { value: 'Kalkaji', label: 'Kalkaji' },
    { value: 'Tughlakabad', label: 'Tughlakabad' },
    { value: 'Badarpur', label: 'Badarpur' },
    { value: 'Okhla', label: 'Okhla' },
    { value: 'Dwarka', label: 'Dwarka' },
    { value: 'Jangpura', label: 'Jangpura' },
  ];
  const handleOnChange = value => {
    //console.log(value.value);
    if (value.value === null) {
      value.value = [];
    }
    else
    {
        setSelected(value);
    }
    //console.log(selected1);
  };
  const search = () => {
    //console.log(clinic.filter((item) => selected === item.address2));
    // if (selected !== null) {
      
    //   clinicfunc();
    //   //setSelected(selected);
    // }
    
    //console.log(selected);
  }; 
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/clinic`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
        localStorage.setItem('clinic', JSON.stringify(data1.data));
        setClinic(data1.data)
		//console.log(category);
      })
  }
  const clinicfunc=()=>
    {
      setClinic(clinic1.filter((item) => item.address2 === selected.value));
    }
    
    useEffect(() => {    
	
      clinicfunc();	
      }, [selected]);
  useEffect(() => {    
	
  fetchData2();	
  }, []);
  useEffect(() => {
    const data = localStorage.getItem('clinic');
    if (data) {
      setClinic(JSON.parse(data));
    }
    
    fetchData1();
   
  }, []);
  
  const fetchData2 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setCenter(data1.data)
		//console.log(category);
      })
  }
  const openLinkInNewTab = ( url ) => {
    const newTab = window.open(url, '_blank', 'noopener,noreferrer');
    if ( newTab ) newTab.opener = null;
  }
  return (
    <div>
	<Header />  
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>     
    <div className="intro">
	    <div className="container" style={{ marginTop: `24px` }}>     
      
      <div className="row">
        <div className="col-md-12">
          <div className="intro-banner-search-form margin-top-95" style={{marginTop: '32%'}}>   
            
            <div className="intro-search-field with-autocomplete">
              <div className="input-with-icon">
			  <Select
        options={options.map(e => ({ label: e.label, value: e.value}))}
        optionLabel="selected Area"       
        labelledBy="Select Area" 
        value={selected} 
        onChange={handleOnChange}
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
                <i className=""></i> </div>
            </div>          
            
           
            {/* <div className="intro-search-button">
			<button type="submit" onClick={search} className="button ripple-effect">Search</button>
            </div> */}
          </div>
        </div>
      </div>
      
      </div>
            </div>
    </div>
   <br/>
   <main>

          <section className="inner_content block">
<div className="container">
<div className="row">

<div className="col-sm-12">
{clinic.map((item) => {
				   const { id,name,start_time,end_time,comment, image,address1,address2,lat1,long1 } = item;
           
           if(lat1 !=null && long1!=null && lt!=null && lng!=null)
           {
           var dis=getDistance(               
            { latitude: lat1, longitude: long1 },
            { latitude: lt, longitude: lng }
               );
              }
              else
              {
                var dis=0;
              }
           var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + lt + "," + lng;
var destination = "&destination=" + lat1 + "," + long1;
var newUrl = new URL(url + origin + destination);
				   return (
					<div key={id}>
  <div className="portfolio_body_n">
<div className="portfolio_card_n">
 <figure><img src="https://booklabonline.com/admin//uploaded_files/event/03064fc9997ae72d7fb66afc616a8c7a.jpg" /></figure>
 <article>
  <span><img src={process.env.REACT_APP_BASE_URL1 + "/assets/img/aam-aadmi-mohalla-clinic.svg"} /></span>
   <p> {comment}</p>
   <p>{address1}</p>
   <p>{address2}</p>
   <p><i className="fa fa-map-marker">{`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}</i></p>
   <button onClick={ () => openLinkInNewTab(newUrl)}> Direction </button>
 </article>

</div>
</div>
</div>
);
})} 
</div>

</div>
</div>
</section>
   </main>
	   <Footer />
    </div>
  );
};
