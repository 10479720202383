import React,{ useState } from "react";
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import ReactWhatsapp from 'react-whatsapp';
import { Button} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState(""); 
  let handleSubmit1 = async (e) => {
    //e.preventDefault();
    try {
      let res = await fetch(`${process.env.REACT_APP_BASE_URL}/contact_us`, {
        method: "POST",
        body: JSON.stringify({
          contact_name: name,
          contact_email: email,
          contact_subject: subject,
          contact_comments: comments,
          contact_phone: phone,
          contact_type: 'Contact'
        }),
      });
      let resJson = await res.json();
      //console.log(resJson);
      if (resJson.status === 1) {
        setName("");
        setEmail("");
        setSubject("");
        setComments("");
        setPhone("");
        setMessage(resJson.message);
      } else {
        setMessage(resJson.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  return (

    <div>
      <Header />
     
      <div className="clearfix"></div>
      <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/bookconatct.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Contact</li>
        </ol>
      </nav>
    </div>
  </div>  
  <div className="section innerblock">
    <div className="container">
      <div className="contact-location-info margin-bottom-60">
        <div className="contact-address">
        <h3 className="contact-address-headline">Our Office</h3>
        <p>For Test Booking, General Enquiry & Other information,<br/>Kindly E-Mail us at <Link to="mailto:mail@.com">bookmylabonline.com</Link></p>
          <ul>
            <li><i className="fa fa-map-marker"></i> Mahipalpur, New Delhi</li>
            <li><i className="fa fa-phone"></i> Phone (123) 4567890</li>
            
            <li><i className="fa fa-clock-o"></i> Monday-Saturday: 08:00 AM – 08:00 PM</li>
            <li><i className="fa fa-clock-o"></i> Sunday: 09:00 AM- 05:00 PM</li>
            <li>
              <div className="freelancer-socials">
                <ul>
                  <li><Link to="#" title="Dribbble" ><i className="fa fa-facebook"></i></Link></li>
                  <li><Link to="#" title="Twitter" ><i className="fa fa-twitter"></i></Link></li>
                  <li><Link to="#" title="Behance" ><i className="fa fa-google-plus"></i></Link></li>
                  <li><Link to="#" title="GitHub" ><i className="fa fa-linkedin"></i></Link></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div id="single-lab-map-container">
        <ReactWhatsapp number="8115876145" message="Hello World!!!"><img src="assets/img/124034.png" style={{ height: 40, width: 40 }}/>Whatsapp Enquiry</ReactWhatsapp>
        	<h3 className="contact-address-headline">Any questions? Feel free to contact us!</h3>
          <form onSubmit={handleSubmit((data) => handleSubmit1(data))}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-with-icon-left">
                  <input className="with-border" name="name" value={name} type="text" id="name" placeholder="Your Name" {...register('name', { required: true })} onChange={(e) => setName(e.target.value)}  />
                  <span style={{ color: 'red' }}>{errors.name && 'Name is required.'}</span>
                  <i className="fa fa-user"></i> </div>
              </div>
              <div className="col-md-6">
                <div className="input-with-icon-left">
                  <input className="with-border" name="email" value={email} type="email" id="email" placeholder="Email Address" {...register('email', { required: true })} onChange={(e) => setEmail(e.target.value)} />
                  <span style={{ color: 'red' }}>{errors.email && 'Email is required.'}</span>
                  <i className="fa fa-envelope-o"></i> </div>
              </div>              
            </div> 
            <div className="row">            
            <div className="col-md-6">
                <div className="input-with-icon-left">
                  <input className="with-border" name="phone" value={phone} type="number" {...register('phone', { required: true })} id="phone" placeholder="Your Phone Number" onChange={(e) => setPhone(e.target.value)}  />
                  <span style={{ color: 'red' }}>{errors.phone && 'Phone is required.'}</span>
                  <i className="fa fa-envelope-o"></i> </div>
              </div> 
              <div className="col-md-6"></div>
              </div>                    
            <div className="input-with-icon-left">
              <input className="with-border" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} type="text" id="subject" placeholder="Subject" />
              <i className="fa fa-edit"></i> </div>
            <div>
              <textarea className="with-border" name="comments" value={comments} onChange={(e) => setComments(e.target.value)} cols="40" rows="5" id="comments" placeholder="Message"></textarea>
            </div>
            <div className="text-center">
            <input type="submit" className="submit button margin-top-15" id="submit" value="Submit Message" />
            </div>
            {message.length > 0 ?
            <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}</div>
             :''}
          </form>
          
        </div>
      </div>
      <div className="clearfix">
      <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448181.163741622!2d76.81306442366602!3d28.64727993557044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1580933218654!5m2!1sen!2sin"
              width="100%"
              height="350"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="myFrame"
            />
        
      </div>
    </div>
    </div>
    <Footer />
    </div>
  );
};
