import React,{ useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { fetchDataWithRetry } from "../../helper/formdata";
export const Team = () => {  
  const [testimonial, setTestimonial] = useState([]);
  const [error, setError] = useState(false);
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/testimonial`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setTestimonial(data1.data)
		//console.log(category);
      })
  }
  useEffect(() => { 
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/testimonial`).then((testimonial) => {
        
      setTestimonial(testimonial.data);
  
    }).catch((error) => {
   setError(error);
   });   
	//fetchData1();	
  //fetchData2();	
  }, []);
  // const fetchData2 = async() => {
  //   await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
  //     .then(response => {
  //       return response.json()
  //     })
  //     .then(data1 => {
		  
  //       setCenter(data1.data)
	// 	//console.log(category);
  //     })
  // }
  
  return (
    <div>
	<Header />  
  
      <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/john-schnobrich-2FPjlAyMQTA-unsplash.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Happy Customer</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  				
				<section className="block staff_sec">
					<div className="container">
					{testimonial.map((item) => {
				   const { id,comment,designation, image,postedby } = item;
				   return (
					<div key={id}>
					    						<div className="staff_detail_card">
							<figure><img src={image}/></figure>
								<div className="detail_staff">
									<h2>{postedby}</h2>
									<h3>{designation}</h3>
									<p>{comment}</p>
								</div>
						</div>
                      </div>
					 );
					})} 
					</div>
				</section>			
			</main>
	   <Footer />
    </div>
  );
};
