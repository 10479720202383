import React,{ useEffect, useState,useContext,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Product } from './Product';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import { CartContext } from '../../context/cart.js'
import {  useSelector,useDispatch } from "react-redux";
import {
  productlistt,productlisting1,productlist_status
} from "../../features/counter/productSlice";
  export const ProductListing = (props) => { 
    const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal,quantity,setQuantity} = useContext(CartContext)
    //console.log(productList);
    const dispatch=useDispatch();
    const productlist111 = useSelector(productlistt);
     const status = useSelector((state) => state.product.status);
    const error = useSelector((state) => state.product.error);
    const [suggestions, setSuggestions] = useState([]);
    const [productList, setProductList] = useState([]);
    const [inputValue, setInputValue] = useState('');
//console.log(productlist);
    useEffect(() => {
      if (inputValue) {
        const filteredSuggestions = productList.filter(product =>
          product.product_name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setProductList(filteredSuggestions);
        //console.log(filteredSuggestions);
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    }, [inputValue]);
  
    const handleChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleSuggestionClick = (suggestion) => {
      setInputValue(suggestion.product_name);
      setSuggestions([]);
    };
   
    useEffect(() => {

      if (status === 'idle') {
        dispatch(productlisting1());
      }
      if(status === 'succeeded')
        {
          setProductList(productlist111);
        }
    }, [status, dispatch]);
   
  //let product_item=this.props.product;
  return (
    <div>
	<Header />  
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="intro">
    <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder="Search for a product..."
      />
      {suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => (
            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
              <Link to={`/productdetails/${suggestion.product_id}`}>{suggestion.product_name}</Link>
            </li>
          ))}
        </ul>
      )}
      </div>      
   <main>
    <br></br>
    <br></br>
    <br></br>
   <div id="hospital-page" className="Up-medicine-page">
        <section className="sort-by-section">
          <div className="container">
            <div className="pull-right medicine-filters-click">
              <a href="#" className="dropdown-filter"><img src="https://www.myupchar.com/images/new_med_home/filter-results-button.png" alt="sort" /> Filter</a> <span>0</span>
            </div>
          </div>
        </section>
        {/* Surgery */}
        <section className="surgery-section">
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <div className="products_container">  
                {
          productList.map(product => {
            return <Product key={product.product_id} item={product}  />
          })}                                 
                </div>                
              </div>
            </div>
          </div>
        </section>       
   </div>
</main>
	   <Footer />
       </div> 
  );
};