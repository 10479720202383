import React from "react";
import { Link } from "react-router-dom";
export const Footer = () => 
{	 
return(
    <>
	
    <footer>
        <div className="container">
            <div className="row footer_bg">
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="footer_content">
                        <img src={process.env.REACT_APP_BASE_URL1+"/assets/img/footer-logo.png"} alt="Logo" className="img-fluid" />
                        <p>
                            Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing.
                        </p>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="footer_content">
                        <h5 className="footer_heading">ABOUT</h5>
                        <div className="customer_services">
                            <ul>
                                <li><Link exact='true' to="/about"><i className="fa fa-arrow-right"></i>About Us</Link>
                                </li>
                                <li><Link exact='true' to="/about"><i className="fa fa-arrow-right"></i>Listing</Link>
                                </li>
                                <li><Link exact='true' to="/how-it-works"><i className="fa fa-arrow-right"></i>How It Works</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Our Services</Link>
                                </li>
                                <li><Link exact='true' to="/blog"><i className="fa fa-arrow-right"></i>Our Blog</Link>
                                </li>
                                <li><Link exact='true' to="/contact"><i className="fa fa-arrow-right"></i>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="footer_content">
                        <h5 className="footer_heading">USEFUL LINKS</h5>
                        <div className="customer_services">
                            <ul>
                                <li><Link exact='true' to="/specialist"><i className="fa fa-arrow-right"></i>Specialist</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Clinic</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Hospital</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Download App</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Join as a Doctor</Link>
                                </li>
                                <li><Link exact='true' to="/"><i className="fa fa-arrow-right"></i>Privacy Policy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>  
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="footer_content">
                        <h5 className="footer_heading">CONTACTS</h5>
                        <div className="visit_us">
                            <i className="fa fa-map-marker"></i>
                            <p>Gali Number 4,Satguru Enclave Rd</p>
                            <p>opposite Maruti Gate,</p>
                            <p>Palam Vihar Extension, Gurugram, Haryana 122022</p>
                        </div>
                        <div className="visit_us">
                            <i className="fa fa-phone-alt"></i>
                            <p>+2(305) 587-3407</p>
                        </div>
                        <div className="visit_us">
                            <i className="fa fa-envelope"></i>
                            <p>info@example.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-7 col-md-6 col-lg-3 order1">
                        <div className="footer_ul">
                            <ul>
                                <li><Link exact='true' to="/">Terms of Service</Link></li>
                                <li><Link exact='true' to="/">Privacy Policy</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 order3">
                        <p>© 2024, <Link exact='true' to="/">Booklab Online</Link>. Powered By @ Namo India</p>
                    </div>
                    <div className="col-sm-5 col-md-6 col-lg-3 order2">
                        <div className="footer_social_icons">
                            <ul className="social_icons">
                                <li>
                                    <Link exact='true' to="/" className="social_icon"> <i className="fa fa-facebook-f"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link exact='true' to="/" className="social_icon"> <i className="fa fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link exact='true' to="/" className="social_icon"> <i className="fa fa-linkedin"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link exact='true' to="/" className="social_icon"> <i className="fa fa-instagram"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </footer>
	
    </>
);

}
