import React,{ Fragment,useEffect, useState } from 'react';
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';
import { getDistance} from 'geolib';
import Paginate from './Paginate';
import { arealist,currentlatitude,currentlongitude} from "../../features/counter/apiSlice";
import { useSelector } from "react-redux";
import { fetchDataWithRetry } from "../../helper/formdata"; 
export const Specialist = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(20);
	const [selected, setSelected] = useState([]);
	const [specialist, setSpecialist] = useState([]);
	const location = useLocation();
	const { ids } = location.state || { ids: "none" };
	const { type } = location.state || { type: "none" };
	const [category, setCategory] = useState([]);
	const [selected1, setSelected1] = useState([]);
	const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [error, setError] = useState(false);
  const areaslistt = useSelector(arealist);
 const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = specialist.slice(indexOfFirstPost, indexOfLastPost);
	//console.log(ids);
	const handleOnChange1 = value1 => {
		//console.log(value1);
		if (value1 === null) {
		  value1 = [];
		}
		else
		{
			setSelected1(value1);
		}
		//console.log(selected1);
	  };
	  const openLinkInNewTab = ( url ) => {
		const newTab = window.open(url, '_blank', 'noopener,noreferrer');
		if ( newTab ) newTab.opener = null;
	  }
	const handleOnChange = value => {   
		if (value === null) {
		  value = [];
		}
		else
		{
			
			setSelected(value);
		}
		//console.log(selected);
	  };
	  async function doctorList() {
		//let selected = {selected };
		if(selected1.length === 0)
    {
        setIsValid(false);
    }
    else if(selected1.length !=undefined)
    {
        setIsValid(true);
    }
    else if(selected.length == 0)
    {
        setIsValid1(false);
    }
    else
    {
        setIsValid1(true); 
		let item = {selected};
		var content={"item":selected,"city":selected1};
        //console.log(selected.length);
	     if(selected.length !==0)
         {
			let result = await fetch(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`,
				{
					method: 'POST',
					body: JSON.stringify(content),
					headers:
					{
						"Content-Type": 'application/x-www-form-urlencoded',
						"Accept": 'application/json'
					}
				})
			result = await result.json();
			//console.log(result);
			setSpecialist(result.data);
            }
		}
	  }	
	  useEffect(() => {
		// If there is data, the form is valid
		setIsValid(selected1 ? true : false);
		setIsValid1(selected ? true : false);
	  }, [selected,selected1]);
	useEffect(() => {
		
		if ((ids === 'none') && (selected.length === 0)) 
		{
			fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_list`).then((doctor) => {
				setSpecialist(doctor.data);
			
			  }).catch((error) => {
			 setError(error);
			 });
			 fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((cat) => {
				setCategory(cat.data);
			
			  }).catch((error) => {
			 setError(error);
			 });	
		// axios
		// 	.get(`${process.env.REACT_APP_BASE_URL}/doctor_list`)
		// 	.then((response) => { setSpecialist(response.data.data); });
		
		// 	axios
		// 	.get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`)
		// 	.then((response) => { setCategory(response.data.data); });
			}
	  }, [ids,selected.length])
	

	 
		useEffect(() => {
			//console.log(selected.length);
			if ((ids !== 'none') && (typeof selected === undefined) && (ids !== 'hospital'))
			{
			//console.log('test');	
			axios
				.get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list/${ids}`)
				.then((response) => { setSpecialist(response.data.data); });
			}
			if ((type == 'hospital') && (typeof selected === undefined))
			{
				
			axios
				.get(`${process.env.REACT_APP_BASE_URL}/hospitallist/${ids}`)
				.then((response) => { setSpecialist(response.data.data); });
			}
		  }, [ids,selected.length])
		  let handleSubmit = async (e) => {
			e.preventDefault();
			try {
			  let res = await fetch(`${process.env.REACT_APP_BASE_URL}/contact_us`, {
				method: "POST",
				body: JSON.stringify({
				  contact_name: name,
				  contact_email: email,
				  contact_subject: subject,
				  contact_comments: comments,
				  contact_phone: phone,
				  contact_type: 'Doctor'
				  
				}),
			  });
			  let resJson = await res.json();
			  //console.log(resJson);
			  if (resJson.status === 1) {
				setName("");
				setEmail("");
				setSubject("");
				setComments("");
				setPhone("");
				setMessage(resJson.message);
			  } else {
				setMessage(resJson.message);
			  }
			} catch (err) {
			  console.log(err);
			}
		  };
		  const paginate = (pageNumber) => {
			setCurrentPage(pageNumber);
	}       
  return (
    <div>
	<Header />
	<div className="banner">
<div className="banner-item banner-bg inner_banner"> 

<div>     
    <div className="intro">
	    <div className="container" style={{ marginTop: `24px` }}>     
      
      <div className="row">
        <div className="col-md-12">
          <div className="intro-banner-search-form margin-top-95">   
            
            <div className="intro-search-field with-autocomplete">
              <div className="input-with-icon">
			  <Select
        options={
			typeof areaslistt != "undefined" &&
			areaslistt.length > 0 &&
			areaslistt.map((e) => ({
			  label: e.city,
			  value: e.id,
			}))
		  }
        optionLabel="selected City"       
        labelledBy="Select City" 
		onChange={handleOnChange1}
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
	  {!isValid && <p style={{color:'red'}}>Please Select City</p>}
                <i className="fas fa-tint"></i> </div>
            </div>          
            
            <div className="intro-search-field">
            <MultiSelect
  options={category.map(e => ({ label: e.doctor_cat_title, value: e.id}))}
  optionLabel="selected Lab"
  value={selected} 
  onChange={handleOnChange}      
   
  labelledBy="Select Lab"
 
/>
{!isValid1 && <p style={{color:'red'}}>Please Select Lab Test</p>}
            </div>
            
            <div className="intro-search-button">
			<button onClick={doctorList}  type="submit" className="button ripple-effect">Search</button>
            </div>
          </div>
        </div>
      </div>
      
      </div>
            </div>
    </div>
            
        </div>
        </div>

    <div className="doctor_list">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 doctor_d">
					<figure style={{ 
      width:'317px'
    }}><img src="assets/img/doctor1.jpg" alt="doctor1"/></figure>
					<div className="inner_column">
						<h3 className="name">Dr. Addision Smith</h3>
						<span className="year">7+ Year</span>
						<span className="designation">Senior Dr. at Delmont (Pediatrics)</span>	
						<span className="pricing1">
							<span className="value"> - $150</span>
							<span className="off">$250</span>
						</span>
						<div>
						<h4>Lorem Ipsum</h4>
						<p>Professor Joshua Clark studied at Havard and qualified in medicine at Harvard Medical School in 1987. He then worked at various New York hospitals for the next five years, including Seattle, and Washington. He also held positions with the Medical Research Council, NY Heart Foundation and the Wellcome Trust.</p>

						<p>He worked as a thoracic and general surgeon in the north of NY and was appointed as a senior researcher in the Medical Research Institute of the New York Academy of Medical Sciences, with the main clinical focus of oesophageal surgery. He continued in this post until his move to the Seattle in the early 1992.</p>

						<p>He worked as a thoracic and general surgeon in the north of NY and was appointed as a senior researcher in the Medical Research Institute of the New York Academy of Medical Sciences, with the main clinical focus of oesophageal surgery. He continued in this post until his move to the Seattle in the early 1992.</p>
						</div>
						<div className="contact_f">
							<h5>Contact Us</h5>
							{message.length > 0 ?
            <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}</div>
             :''}
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<input type="text" name="name" value={name} id="name" placeholder="Your Name" onChange={(e) => setName(e.target.value)}/>
								</div>	
								<div className="form-group">
								<input type="number" name="phone" value={phone} id="phone" placeholder="Your Phone Number" onChange={(e) => setPhone(e.target.value)}/>
								</div>
								<div className="form-group">
									<input type="email" name="email" value={email} id="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}/>
								</div>	
								<div className="form-group">
								<input className="with-border" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} type="text" id="subject" placeholder="Subject"/>
								</div>	
								<div className="form-group">
								<textarea className="with-border" name="comments" value={comments} onChange={(e) => setComments(e.target.value)} cols="40" rows="5" id="comments" placeholder="Message"></textarea>
								</div>								
								<div className="form-group">
									<button className="send_btn" type="submit" name="submit-form"><span className="btn-title">Send</span></button>
								</div>
							</form>
						</div>
						
					</div>
				</div>
                <div className="col-sm-6 col-md-6 col-lg-6">
					<div className="doctor_l">
					{typeof currentPosts != 'undefined' && currentPosts.length > 0 && currentPosts && (
						<>
						{currentPosts.map((item) => {
				   const { id, address1,address2,doctor_image, first_name,last_name,lat1,long1,doctor_price,doctor_cat_title,doctor_overview,doctor_experience } = item;
				   if(lat1 !=null && long1!=null && lt!=null && lng!=null)
           {
           var dis=getDistance(               
            { latitude: lat1, longitude: long1 },
            { latitude: lt, longitude: lng }
               );
              }
              else
              {
                var dis=0;
              }
			  var url = "https://www.google.com/maps/dir/?api=1";
			  var origin = "&origin=" + lt + "," + lng;
			  var destination = "&destination=" + lat1 + "," + long1;
			  var newUrl = new URL(url + origin + destination);
				   return (
				   <div key={id}>
						<div className="new_dr_card">
							<div className="social_m">
								<ul className="social_icons">
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-facebook-f"></i>
										</Link>
									</li>
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-twitter"></i>
										</Link>
									</li>
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-instagram"></i>
										</Link>
									</li>
								</ul>
							</div>
							<div className="dr_card_img">
								<Link to="#"><figure><img title="" alt="" src={doctor_image} /></figure></Link>
							</div>
							<div className="dr_card_content">
								<h3 className="dr_name">{first_name} {last_name}</h3>
								<span className="dr_year">{doctor_experience}</span>
								<span className="dr_designation">{doctor_cat_title}</span>	
								<span className="dr_pricing">
									<span className="value"><i className="fa fa-inr" aria-hidden="true"></i>  {doctor_price}</span>
									
								</span>
								<hr/>
								<p>{doctor_overview}</p>
								<p>{address1}</p>
                               <p>{address2}</p>
								<p><i className="fa fa-map-marker">{`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}</i></p>
   <button onClick={ () => openLinkInNewTab(newUrl)}> Direction </button>
								<p><Link to={`/specialist/${id}`} className="know-more-btn">View More</Link></p>
							</div>
						</div>
						</div>
						);
          })}
						</>
						)}
					</div>
					
				</div>
				<Paginate
                  postsPerPage={postsPerPage}
                  totalPosts={specialist.length}
                  paginate={paginate}
               />
			</div>
		</div>
	</div>

	   <Footer />
    </div>
  );
};
