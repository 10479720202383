
import useFormContext from "../hooks/useFormContext"

const Form = () => {

    const {
        page,
        setPage,
        data,
        title,
        canSubmit,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide
    } = useFormContext()
    const handlePrev = () => setPage(prev => prev - 1)
    const handleNext = () => setPage(prev => prev + 1)
    const handleSubmit = e => {
        e.preventDefault()
        console.log(JSON.stringify(data))
    }
   
    const content = (
        <div>
        {
            page === 1 ? (
        <div className="button-container">
            <button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>   
        <button type="submit" className={`button ${submitHide}`}>Submit
        <span></span>
      <span></span>
      <span></span>
      <span></span>
        </button>                 
                    </div>
                    ) : (
                        <div className="button-container"><button type="button" className={`button ${prevHide}`} onClick={handlePrev} disabled={disablePrev}>Prev</button>

                    <button type="button" className={`button ${nextHide}`} onClick={handleNext} disabled={disableNext}>Next
                    <span></span>
      <span></span>
      <span></span>
      <span></span>
      </button>
                    <button type="submit" className={`button ${submitHide}`} disabled={!canSubmit}>Submit</button>
                    </div>
                      )
                    }
        </div>          
    )

    return content
}
export default Form