import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
export const Digital = () => {
  
  const [lab, setLab] = useState([]);
  const [center, setCenter] = useState([]);  
  const fetchData1 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/home_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setLab(data1.data)
		//console.log(category);
      })
  }
  useEffect(() => {    
	fetchData1();	
  fetchData2();	
  }, []);
  const fetchData2 = async() => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then(response => {
        return response.json()
      })
      .then(data1 => {
		  
        setCenter(data1.data)
		//console.log(category);
      })
  }
  
  return (
    <div>
	<Header />  
  
      <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/wepik-export-20240313154738gaQb.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Digital India</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>
        <section className="block">
          <div className="container">
            <h3 className="heading3">Energy Management Systems</h3>
            <p>भारतीय विशिष्‍ट पहचान प्राधिकरण (Unique Identification Authority of India) सन् २००९ में गठित भारत सरकार का एक प्राधिकरण है जिसका गठन भारत के प्रत्येक नागरिक को एक बहुउद्देश्यीय राष्ट्रीय पहचान पत्र उपलब्ध करवाने की भारत सरकार की एक महत्वाकांक्षी योजना के अन्तर्गत किया गया। भारत के प्रत्येक निवासियों को प्रारंभिक चरण में पहचान प्रदान करने एवं प्राथमिक तौर पर प्रभावशाली जनहित सेवाऐं उपलब्ध कराना इस परियोजना का प्रमुख उद्देश्य था</p>
            <div className="listing_box">			
              <ul>
                <li>
                  <div className="list_single_box text-center">
                    <div className="icon">
                      <img src="https://www.arcticcoolsys.com/images/icon5.png" />
                    </div>
                    <h2>Aadhaar Card</h2>
                  </div>
                </li>
                <li>
                  <div className="list_single_box text-center">
                    <div className="icon">
                      <img src="https://www.arcticcoolsys.com/images/icon5.png" />
                    </div>
                    <h2>Pan Card</h2>
                  </div>
                </li>
                <li>
                  <div className="list_single_box text-center">
                    <div className="icon">
                      <img src="https://www.arcticcoolsys.com/images/icon5.png" />
                    </div>
                    <h2>Ayushman Card</h2>
                  </div>
                </li>               
              </ul>
            </div>
          </div>
          <div className="services_b">
            <div className="container">
              <div className="row">
                {/*Services Block*/}
                <div className="services_box col-md-4 col-sm-6 col-xs-12">
                  <div className="inner_box">
                    <div className="image">
                      <a href="https://myaadhaar.uidai.gov.in/genricDownloadAadhaar" target="_blank"><img src="assets/img/Bal-Aadhaar-Banner-1280x720.jpg" alt="" /></a>
                    </div>
                    <div className="lower_content">
                      <h3><a href="https://myaadhaar.uidai.gov.in/genricDownloadAadhaar" target="_blank">Aadhaar Card</a></h3>
                    </div>
                  </div>
                </div>
                <div className="services_box col-md-4 col-sm-6 col-xs-12">
                  <div className="inner_box">
                    <div className="image">
                      <a href="https://www.onlineservices.nsdl.com/paam/requestAndDownloadEPAN.html" target="_blank"><img src="assets/img/pan-card-Banner.jpg"  style={{"height" : "232px"}} alt="" /></a>
                    </div>
                    <div className="lower_content">
                      <h3><a href="https://www.onlineservices.nsdl.com/paam/requestAndDownloadEPAN.html" target="_blank">Pan Card</a></h3>
                    </div>
                  </div>
                </div>
                <div className="services_box col-md-4 col-sm-6 col-xs-12">
                  <div className="inner_box">
                    <div className="image">
                      <a href="https://bis.pmjay.gov.in/BIS/selfprintCard" target="_blank"><img src="assets/img/ayushman-card.jpg" style={{"height" : "232px"}} alt="" /></a>
                    </div>
                    <div className="lower_content">
                      <h3><a href="https://bis.pmjay.gov.in/BIS/selfprintCard" target="_blank">Ayushman Card</a></h3>
                    </div>
                  </div>
                </div>               
              </div>
            </div>
          </div>
        </section>  
      </main>
	   <Footer />
    </div>
  );
};
   