import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { fetchDataWithRetry } from "../../helper/formdata"; 
export const Partner = () => {
  const [partner, setPartner] = useState([]);
  const [center, setCenter] = useState([]);
  const [error, setError] = useState(false);
  const fetchpartner = async() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/partner`).then((partner) => {
      setPartner(partner.data);
  
    }).catch((error) => {
   setError(error);
   });
  }
  useEffect(() => {    
    fetchpartner();	
  }, []);
  
  return (
    <div>
	<Header />  
  
      <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/business-partnership.webp)`,marginTop: `110px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Our Partner</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  
        <div className="container">
          <section className="block bottom_padding">
          {partner.map((item) => {
				   const { id,title,description, image } = item;
				   return (
					<div key={id}>
            <div className="partners_b">
              <div className="partners_img">
                <div className="deals_icon"><img src="" alt="" /></div>
                <img src={image} className="center_img" alt="" />
              </div>
              <div className="partners_content">
                <h4>{title}</h4>
                <p>{description}</p>
                {/*<p>Hill Phoenix Barker Specialty manufactures specialty display cases for bakeries, delis, food bars, and other retail applications. We partner with Barker Specialty to offer our customers custom display case solutions. These specialty made to order parts are essential to cases and their functionality. No aftermarket parts, just authentic original parts.</p>*/}
              </div>
            </div> 
            </div>            
             );
            })} 
          </section>
        </div>
       
      </main>
	   <Footer />
    </div>
  );
};
   