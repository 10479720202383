  import React,{ useEffect, useState,useContext } from 'react';
import { Link,useLocation,useParams } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import OwlCarousel from 'react-owl-carousel';
import { CartContext } from '../../context/cart';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./image-gallery.css";

export const ProductDetails = (props) => { 
  const options = Array.from({ length: 10 }, (v, k) => ({
    value: k + 1,
    label: (k + 1).toString()
  }));

	const params = useParams();
	const location = useLocation();
  const [productdtail1, setProductDtail1] = useState([]);
  const [productimage, setProductImage] = useState([]);
  const [quantity1, setQuantity1] = useState([]);
  const [qunt, setQunt] = useState();
  const { cartItems, addToCart , removeFromCart,quantity=quantity1,setQuantity=setQuantity1} = useContext(CartContext)
  //console.log(cartItems);
  // const onChange1 = (event) => {
  //   const value = event.target.value;
    
  //   setQuantity1(value);
  // };
	  const images1 = [
		{
		  original: 'https://picsum.photos/id/1018/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1018/1000/400/',
		},
		{
		  original: 'https://picsum.photos/id/1015/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1015/1000/400/',
		},
		{
		  original: 'https://picsum.photos/id/1026/1000/400/',
		  thumbnail: 'https://picsum.photos/id/1026/1000/400/',
		}
	  ];
	  useEffect(() => {		
		
		const getProductdetail = async () => {
		  try {
			const res = await axios.get(
			  `${process.env.REACT_APP_BASE_URL}/productdetails/${params.id}`,
			);
			//console.log(res.data.data);
			setProductDtail1(res.data.data);
			setProductImage(res.data.data.product_images);
		  } catch (e) {
			//console.log('error');
			console.log(e);
		  }
		};
		getProductdetail();
	  }, [params.id]);
	  
	  //console.log(productdtail1);
    const notifyAddedToCart = (item) => toast.success(`${item} added to cart!`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
      style: {
        backgroundColor: '#fff',
        color: '#000',
      }
      });
      const handleOnChange1 = value1 => {
        //console.log(value1.value);
        setQunt(value1);
           setQuantity1(value1.value);
       
      }; 
      const handleRemoveFromCart = (product) => {
        removeFromCart(product);
        notifyRemovedFromCart(product);
      };
      const notifyRemovedFromCart = (item) => toast.error(`${item.product_name} removed from cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff',
        }
        });
	  //console.log(image);
  return (
    
    <div>
      
	<Header /> 
      
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br></br>      
   <main>  
   <div className="flex-col flex items-center fixed inset-0 left-1/4 bg-white dark:bg-black gap-8  p-10  text-black dark:text-white font-normal uppercase text-sm">
      
   <div className="row OtcPage__top-container___2JKJ-">
  <div className="OtcPage__breadcrumb-container___ib1Xx">
    <div itemType="http://schema.org/BreadcrumbList" className="">
      <span itemProp="itemListElement" itemType="http://schema.org/ListItem">
        <a
          itemProp="item"
          href="/"
          className="button-text Breadcrumbs__breadcrumb___XuCvk"
        >
          <span itemProp="name">Home</span>
          <meta itemProp="position" content={0} />
        </a>
      </span>
      <span className="Breadcrumbs__separator___Orr-j" />
      <span itemProp="itemListElement" itemType="http://schema.org/ListItem">
        <a
          itemProp="item"
          href="/categories/vitamins-nutrition-5"
          className="button-text Breadcrumbs__breadcrumb___XuCvk"
        >
          <span itemProp="name">Vitamins &amp; Nutrition</span>
          <meta itemProp="position" content={1} />
        </a>
      </span>
      <span className="Breadcrumbs__separator___Orr-j" />
      <span itemProp="itemListElement" itemType="http://schema.org/ListItem">
        <a
          itemProp="item"
          href="/categories/vitamins-supplements-9"
          className="button-text Breadcrumbs__breadcrumb___XuCvk"
        >
          <span itemProp="name">Vitamins &amp; Supplements</span>
          <meta itemProp="position" content={2} />
        </a>
      </span>
      <span className="Breadcrumbs__separator___Orr-j" />
      <span itemProp="itemListElement" itemType="http://schema.org/ListItem">
        <a
          itemProp="item"
          href="/categories/minerals-161"
          className="button-text Breadcrumbs__breadcrumb___XuCvk"
        >
          <span itemProp="name">Minerals</span>
          <meta itemProp="position" content={3} />
        </a>
      </span>
      <span className="Breadcrumbs__separator___Orr-j" />
      <span>
        <span className="Breadcrumbs__sku-name___aGbfp">zincogut syrup</span>
      </span>
    </div>
  </div>
  <div className="col-sm-4">
  <div className="image-gallery-wrapper">
	<ImageGallery items={productimage} item={1} autoPlay={false} showBullets={true} showFullscreenButton={false} />
	</div>
  </div>
  <div className="col-sm-4">
    <div>
      <div>
        <h1 className="ProductTitle__product-title___3QMYH">{productdtail1.product_name}</h1>
      </div>
      <div className="ProductTitle__marketer___7Wsj9">
        <a href="/marketer/centaur-pharmaceuticals-pvt-ltd-16">
          Centaur Pharmaceuticals Pvt Ltd
        </a>
      </div>
      <div className="ProductTitle__ratings___4MWF_">
        <div
          className="RatingDisplay__ratings-container___3oUuo"
          style={{ backgroundColor: "#1AAB2A" }}
        >
          <span>4.4</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={15}
            viewBox="0 0 51 48"
          >
            <path
              fill="#ffffff"
              stroke="none"
              d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
            />
          </svg>
        </div>
        <span className="RatingDisplay__ratings-header___ZNj5b">
          18 Ratings &amp; 3 Reviews
        </span>
      </div>
    </div>
    <div className="ProductHighlights__product-highlights___2jAF5">
      <div className="ProductHighlights__highlights-header___3YmSt">
        Product highlights
      </div>
      <div className="ProductHighlights__highlights-text___dc-WQ">
        <ul>
          <li>Indicated for the overall development of the human body</li>
        </ul>{" "}
        <ul>
          <li>
            Aids bodily functions such as immune function, wound healing and
            blood clotting
          </li>
        </ul>{" "}
        <ul>
          <li>Facilitates the development and strengthening of the bones</li>
        </ul>{" "}
        <ul>
          <li>Helps prevent zinc deficiencies in the human body</li>
        </ul>
      </div>
    </div>
  </div>
  <div className="col-sm-4">
    <div>
      <div className="OtcPriceBox__atc-box___30PES">
        <div className="SocialCue__social-cue-box___2fIau">
          <span className="SocialCue__views-icon___1G33-">
            <img src="https://www.1mg.com/images/social_cue.svg" alt="social-cue" />
          </span>
          <span className="SocialCue__views-text___1CTJI">
            34 people bought this recently
          </span>
        </div>
        <div className="OtcPriceBox__price-box___p13HY">
          <div className="PriceBoxPlanOption__option-container___3BpVv">
            <div className="PriceBoxPlanOption__flex___3c7VS PriceBoxPlanOption__align-center___6zWOL PriceBoxPlanOption__margin-bottom-16___3STIk">
              <div className="PriceBoxPlanOption__margin-right-12___3C4UW PriceBoxPlanOption__padding-top-9___1_vWO">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#FF6F61"
                      fillRule="evenodd"
                      d="M12 7c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0-5C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
                    />
                  </svg>
                </span>
              </div>
              <div>
                <span className="PriceBoxPlanOption__offer-price___3v9x8 PriceBoxPlanOption__offer-price-cp___2QPU_">
                  ₹{/* */}118
                </span>
                <span className="PriceBoxPlanOption__margin-right-4___2aqFt PriceBoxPlanOption__stike___pDQVN">
                  ₹{/* */}129.73
                </span>
                <span className="PriceBoxPlanOption__discount___iN_jm">
                  9% off
                </span>
              </div>
            </div>
            <div className="PriceBoxPlanOption__flex___3c7VS PriceBoxPlanOption__align-center___6zWOL PriceBoxPlanOption__margin-bottom-16___3STIk">
              <div className="PriceBoxPlanOption__margin-right-12___3C4UW PriceBoxPlanOption__padding-top-9___1_vWO">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#767676"
                      fillRule="evenodd"
                      d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
                    />
                  </svg>
                </span>
              </div>
              <div>
                <div className="">
                  <span className="PriceBoxPlanOption__offer-price___3v9x8 PriceBoxPlanOption__offer-price-cp___2QPU_">
                    ₹{/* */}118
                  </span>
                  <span className="PriceBoxPlanOption__offer-text___1Lim9 PriceBoxPlanOption__m-0___gnRUo">
                    + free shipping with
                  </span>
                  <span className="PriceBoxPlanOption__offer-text___1Lim9" />
                  <img
                    src="https://onemg.gumlet.io/v1613645053/marketing/phb2bz61etrdmuurfdoq.png"
                    className="PriceBoxPlanOption__logo___1XjhH PriceBoxPlanOption__m-0___gnRUo"
                    alt="Care Plan Logo"
                  />
                  <span className="PriceBoxPlanOption__offer-text___1Lim9" />
                </div>
                <div />
              </div>
            </div>
            <div className="PriceBoxPlanOption__disclaimer-text___1yqjJ">
              <div>Inclusive of all taxes</div>
            </div>
          </div>
          {
          !cartItems.find(item => item.product_id === productdtail1.product_id) ?
          <div className="OtcPriceBox__add-box___3rvCP">
            
          <Select
        options={options}
        optionLabel="selected Quantity"
        onChange={handleOnChange1}
        value={qunt} 
        defaultValue={1}
        labelledBy="Select Quantity" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
          </div>
         : 
          <>
          
          </>
        
      }
          <div className="AddToCart__add-to-cart___39skL">
            <div className="style__cart-button___3CZnL">
            {
                  !cartItems.find(item => item.product_id === productdtail1.product_id) ? (
              <div className="style__interaction___3cb12 style__otcPage-AddToCart___1ASzH" onClick={() => {
                        addToCart(productdtail1.product_id,quantity1)
                        notifyAddedToCart(productdtail1.product_name)
                      }
                      }>
                Add to cart
              </div>
              ) : (
                <div className="flex gap-4">
                    <button
                      className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                      onClick={() => {
                        addToCart(productdtail1.product_id,1)
                      }}
                    >
                      +
                    </button>
                    <p className='text-gray-600'>{cartItems.find(item => item.product_id === productdtail1.product_id).quantity}</p>
                    <button
                      className="px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.product_id === productdtail1.product_id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(productdtail1);
                        } else {
                          removeFromCart(productdtail1);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                 )
                }
               </div>
          </div>
        </div>
      </div>
    </div>
    <div id="eta-content" className="OtcPage__eta-wrapper___2w2C7">
      <div className="style__box___1ez55">
        <div className="style__padded___2vNu9 style__headerText___3sw_C">
          <span style={{ color: "#3B3B3B" }}>Earliest delivery by</span>{" "}
          <span style={{ color: "#00B62F" }}>11am, Today</span>
        </div>
        <div className="style__deliveryBox___g_mGG style__padded___2vNu9 style__marginTop-12___36A_e">
          <div className="style__deliveryHeader___1KRla">Delivering to:</div>
          <div className="style__marginLeft-4___fp1Ae style__delivery-cta___1C7Gt">
            <span className="style__deliveryText___1f5Qp">122019, Gurgaon</span>
            <span className="style__marginLeft-4___fp1Ae style__chevron-icon___2Ve1O">
              <img
                alt="Down Arrow Icon"
                src="https://onemg.gumlet.io/jj74lyp3teqhlxoo1qkz.svg"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      id="div-gpt-ad-1471501284459-0"
      className="hidden-sm hidden-xs dfp-responsive OtcPage__dfp-container___2LC7p"
    />
    <div className="hidden-sm hidden-xs dfp-responsive OtcPage__dfp-container___2LC7p">
      <div className="otc-banner-slider ">
        <div>
          <div className="styles__otc-banner___2w86W">
            <a
              aria-label="banner image"
              href="https://www.1mg.com/search/all?name=diataal&filter=true&brand=Diataal"
              target="_blank"
              rel="noopener"
            >
              <img
                src="https://onemg.gumlet.io/d32f286e-e216-4906-bfc6-957dc23d4da6_1707542267.jpg?w=383&h=88&format=auto"
                alt=""
                className="styles__banner-image___2Ysqf"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="AdditionalOffers__offer-container___PvU82">
      <h2 className="AdditionalOffers__offer-heading___EBbx1">
        Additional offers
      </h2>
      <div>
        <div className="AdditionalOffers__offer-content___24UBK">
          <div className="AdditionalOffers__offer-icon___1lvPs">
            <div className="" />
          </div>
          <div className="AdditionalOffers__offer-detail___3WqhK">
            <b>Amazon Pay Later</b>: Pay with Amazon Pay Later on Tata 1mg for
            Rs. 499 and more and earn cashback up to Rs. 300 with minimum
            cashback of Rs. 15. Offer ends 29th Feb'24.
          </div>
        </div>
      </div>
      <div className="AdditionalOffers__show-buttons___37fl6">
        <span>
          Show more{" "}
          <img
            src="https://onemg.gumlet.io/t2plj2wvywoglfwll4cm_y7momq.svg"
            alt="show_more"
          />
        </span>
      </div>
    </div>
  </div>
</div>
</div>
</main>
	   <Footer />
       </div> 
  );
};
