import React,{ useEffect, useState } from "react";
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
import {ReadMore} from './ReadMore';
import TimePicker from "react-bootstrap-time-picker";
import { useDispatch, useSelector } from "react-redux";
export const HealthDetails = () => {
  const token = JSON.parse(localStorage.getItem("user-info-login"));
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();
  const { ids } = location.state || { ids: "none" };
  const [labid, setLabId] = useState(ids);
  const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:labid,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'health package'})
  const handleClose = () => setShow(false);
  const [ health, setHealth] = useState([]);  
  const [name, setName] = useState("");
  const [selected, setSelected] = useState([]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [time, setTime] = useState("10");	
  const [message, setMessage] = useState(""); 
  const [password, setPassword] = useState("");
  
  const dispatch = useDispatch();
   useEffect(() => {
    if (ids !== 'none')
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/health_list/${ids}`)
      .then((response) => { //console.log(response.data.data[0].health_title);
        setHealth(response.data.data[0]); });
    }, [ids])   
  const labclick = () => {  
    //console.log('test'); 
    setShow(true);    
  };
  
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
  const handleSubmit=async(e)=>
    {
      //console.log('test');
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
  return (
    <div>
       <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title></Modal.Title>
  </Modal.Header>
  <Modal.Body><div className="row test-name">
      <div className="col-md-1" />
      <div className="col-md-10" align="center">
      <h3>{health.health_title}</h3>   
        <h4><i className="fa fa-map-marker" aria-hidden="true" /> (  4A/16, Block 4A, Tilak Nagar )</h4>
      </div>
      <div className="col-md-1" />
    </div>
    <div className="col-md-1" />
  <div className="row">
      <div className="col-md-12" style={{padding: 0}}>
        <div className="my-table table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr style={{background: '#1CBBB4', color: '#fff'}}>
                <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Test Name</th>
                <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>MRP</th>
                <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Discount</th>
                <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Payable</th>
                <th style={{textAlign: 'center', fontFamily: 'sans-serif', fontSize: '15px'}}>Include Parameters</th>
              </tr>
            </thead>
           
           
       
      <>
      <tbody>
      {typeof health.include != 'undefined' && health.include.length > 0 && health.include && (
									<>
									
                      <tr key={health.health_id}>
                <td>{health.health_title}</td>
                <td>{health.health_market_price}</td>
                <td>{health.discount}</td>
                <td>{health.health_sell_price}</td>
                <td>
									<div>
									{health.include.map((item1,j) => {
										return (
                      <div key={item1}>
                {item1}
                </div>
                );
										})}
										</div>
									 </td>
                </tr>                
										</>
										)}
                    </tbody>
      </>
     
            
           
          </table>
          <div className="inner-box">
                                  <div className="text">
                                    <div className="doctors-sidebar comment-bottom" style={{paddingRight: 30,
    paddingLeft: 30}}>
                                      <form style={{boxShadow: '0px 0px 0px #7b7b7b'}} onSubmit={handleSubmit}>                                     
                                        <div className="form-widget" style={{border: '1px solid #999',backgroundImage: `url("http://localhost:3000/assets/img/health-checks-bg.jpg")`,"width" : "102%"}}               
                                      >
                                          <div className="form-title">
                                            <h3>Book Appointment</h3>                                          
                                            <p>
                                              Monday to Friday: 09:00Am-05:00PM
                                            </p>
                                          </div>
                                          {(token != null ? 
                                          <div className="message contact-address-headline success"></div>
                                           : ''   )}
                                          <div className="form-inner">
                                            <div className="appointment-time">
                                              <input
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                value={
                                                  token != null
                                                    ? token["name"]
                                                    : inputValue.name
                                                }
                                                onChange={handleInput}
                                                required="required"
                                              />
                                              <input
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                value={
                                                  (typeof token != 'undefined' && token != null) ? token.currentUser.email:inputValue.email
                                                }
                                                onChange={handleInput}
                                                required="required"
                                              />
                                              {(token == null) ?
                                        <div className="form-group">
                                            <input type="password" name="password" placeholder="Password"  value={inputValue.password} onChange={handleInput} required="required"/>
                                        </div>
                                        : ''}
                                              <input
                                                type="text"
                                                name="phone"
                                                placeholder="Phone Number"
                                                onChange={handleInput}
                                                value={
                                                  token != null
                                                    ? token["phone"]
                                                    : inputValue.phone
                                                }
                                                required="required"
                                              />
                                              <select
                                                className=""
                                                name="gender"
                                                value={inputValue.gender}
                                                onChange={handleInput}
                                                required="required"
                                             style={{border: '1px solid #999'}} >
                                                <option data-display="Select Your Gender">
                                                  Select Your Gender
                                                </option>
                                                <option value="Male">
                                                  Male
                                                </option>
                                                <option value="Female">
                                                  Female
                                                </option>
                                              </select>  
                                              <br></br>                                            
                                                <input
                                                  type="text"
                                                  name="age"
                                                  placeholder="Age"
                                                  required="required"
                                                  valu={inputValue.age}
                                                  onChange={(e) =>
                                                    setAge(e.target.value)
                                                  }
                                                />
                                             <div className="form-group">
                            <DatePicker selected={startDate} minDate={startDate} value={startDate} onChange={(date) => setStartDate(date)} required="required"/>
                                <i className="fa fa-calendar-alt"></i>
                            </div>
                            <div className="form-group">
                            <TimePicker selected={time} start="10:00" end="21:00" value={time} step={30} onChange={(e) => setTime(e.target.value)} required="required"/>
                                <i className="far fa-clock"></i>
                            </div>
                                            </div>
                                            <div className="choose-service">
                                              <div className="btn-box">
                                                <button
                                                  type="submit"
                                                  className="theme-btn-one"
                                                >
                                                  Book Appoinment
                                                  <i className="icon-Arrow-Right" />
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div> 
        </div>
      </div>
    </div></Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal>
	<Header />
    <div className="clearfix"></div>
  <div className="single-page-header" style={{backgroundImage: `url("assets/img/bg1.jpg")`,marginTop:'90px'}}>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="single-page-header-inner">
            <div className="left-side">
              <div className="header-details">
                <h3>Basic Screening Package</h3>
                <h5>full body test, full body checkup, fullbody checkup, fullbody test, complete health, entire body</h5>
                <ul>
                  <li>KParameters Includeding</li>
                  <li><span>60</span></li>
                </ul>
              </div>
            </div>
            <div className="right-side">
              <div className="salary-box">
                <div className="salary-type">Healthians Price: <i className="fa fa-rupee"></i> 820</div>
                <div className="salary-amount">Offer Price <i className="fa fa-rupee"></i> 450</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item"><Link to="#">Health Deals</Link></li>
          <li className="breadcrumb-item active">Basic Screening Package</li>
        </ol>
      </nav>
    </div>
  </div>
  <div className="section innerblock deal_details">
    <div className="container">
      <div className="listings-container compact-list-layout margin-bottom-25">
        <div className="lab-listing">
          <div className="lab-listing-details">
            <div className="lab-listing-company-logo"> <img src="assets/img/pkg.png" alt="" /> </div>
            <div className="lab-listing-description">
              <h3 className="lab-listing-title">Recommended for</h3>
              <div className="lab-listing-footer">
                <ul>
                  <li><i className="fa fa-male"></i> Male</li>
                  <li><i className="fa fa-female"></i> Female</li>
                  <li><i className="fa fa-calendar"></i> 5-99 Years</li>
                </ul> 
              </div>
              <Link to="#" className="button button-sliding-icon textright">Book Now <i className="fa fa-long-arrow-right"></i></Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        	<div className="col-sm-5">
            	<img src="assets/img/img10.jpg" className="img-responsive img-thumbnail"  alt="assets/img/img10.jpg"/>
            </div>
            <div className="col-sm-7">
            
            	<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            </div>
        </div>
       
      <h4 className="heading1">Test Details (Parameters included : 60)</h4>
      <div className="panel-group" id="accordion">
        <div className="panel panel-default">
          <div className="panel-heading">
            <h4 className="panel-title"> <div data-toggle="collapse" data-parent="#accordion" href="#collapse1"> {health.health_title}</div> </h4>
          </div>
          
          <div id="collapse1" className="panel-collapse collapse in">
            <div className="panel-body">
              <ul className="list-1">
              {typeof health.include != 'undefined' && health.include.length > 0 && health.include && (
									<div>
									{health.include.map((item1,j) => {
										return (
                      <li key={item1+1}>{item1}</li>
                );
										})}
										</div>
										)}
              </ul>
            </div>
          </div>
        </div>
        
      </div>
      <div className="text-center">
      	<button  onClick={() => labclick()} className="button button-sliding-icon">Book Now <i className="fa fa-long-arrow-right"></i></button>
      </div>
    </div>
  </div>
	   <Footer />
    </div>
  );
};
