import React,{ useEffect, useState } from "react";
import { Link,useNavigate} from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
export const Dashboard = () => {
  //console.log({profile});
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  //console.log(token);
const [profile, setProfile] = useState([]);
  var id=(typeof token.currentUser != 'undefined' ? token.currentUser.uid:token['id']);
useEffect(() => {  
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/profile/${id}`)
    .then((response) => { setProfile(response.data.data); });
  
  }, [id])
  const navigate = useNavigate();
  const logout = (e) => {
    e.preventDefault();
    //console.log('Logout');   
    // CLEAR DATA FROM STORAGE
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
}
  return (
    <div>
	<Header />
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>
  <section className="patient-dashboard bg-color-3">
        <div className="left-panel">
          <div className="profile-box patient-profile">
            <div className="upper-box">
              <figure className="profile-image">
                <img src={(typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null) ? token.currentUser.photoURL : ""} alt="" /></figure>
              <div className="title-box centred">
                <div className="inner">
                  <h3>{(typeof token.currentUser != 'undefined' && token.currentUser.email != null) ? token.currentUser.email:token['email']}</h3>
                  <p><i className="fas fa-calendar-alt" />Oct 05, 2083,  38 Years</p>
                </div>
              </div>
            </div>
            
            <div className="profile-info">
            <ul className="list clearfix">
                <li><Link to="/dashboard" className="current"><i className="fas fa-columns" />Dashboard</Link></li>
                <li><Link to="/favorite_doctor"><i className="fas fa-heart" />Favourite Doctors</Link></li>  
                <li><Link to="/schedule"><i className="fas fa-user" />Schedule Timing</Link></li>              
                <li><Link to="/profile"><i className="fas fa-user" />My Profile</Link></li>
                <li><Link to="/change_password"><i className="fas fa-unlock-alt" />Change Password</Link></li>
                <li><Link to="/" onClick={logout}><i className="fas fa-sign-out-alt" />Logout</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right-panel">
          <div className="content-container">
            <div className="outer-container">
              <div className="feature-content">
                <div className="row clearfix">
                  <div className="col-xl-4 col-lg-12 col-md-12 feature-block">
                    <div className="feature-block-two">
                      <div className="inner-box">
                        <div className="pattern">
                          <div className="pattern-1" style={{backgroundImage: 'url(assets/img/shape/shape-79.png)'}} />
                          <div className="pattern-2" style={{backgroundImage: 'url(assets/img/shape/shape-80.png)'}} />
                        </div>
                        <div className="icon-box"><i className="icon-Dashboard-3" /></div>
                        <h3>170</h3>
                        <h5>Doctor Booking</h5>                       
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-12 feature-block">
                    <div className="feature-block-two">
                      <div className="inner-box">
                        <div className="pattern">
                          <div className="pattern-1" style={{backgroundImage: 'url(assets/img/shape/shape-81.png)'}} />
                          <div className="pattern-2" style={{backgroundImage: 'url(assets/img/shape/shape-82.png)'}} />
                        </div>
                        <div className="icon-box"><i className="icon-Dashboard-email-4" /></div>
                        <h3>20</h3>
                        <h5>Lab Booking</h5>                       
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-12 col-md-12 feature-block">
                    <div className="feature-block-two">
                      <div className="inner-box">
                        <div className="pattern">
                          <div className="pattern-1" style={{backgroundImage: 'url(assets/img/shape/shape-83.png)'}} />
                          <div className="pattern-2" style={{backgroundImage: 'url(assets/img/shape/shape-84.png)'}} />
                        </div>
                        <div className="icon-box"><i className="icon-Dashboard-5" /></div>
                        <h3>50</h3>
                        <h5>Health Package</h5>                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="doctors-appointment">
                <div className="title-box">
                  <h3>Doctors Appointment</h3>
                  <div className="btn-box">
                    <Link href="patient-dashboard.html" className="theme-btn-one">Upcoming<i className="icon-Arrow-Right" /></Link>
                    <Link href="patient-dashboard.html" className="theme-btn-two">Today</Link>
                  </div>
                </div>
                <div className="doctors-list">
                  <div className="table-outer">
                    <table className="doctors-table">
                      <thead className="table-header">
                        <tr>
                          <th>Doctor Name</th>
                          <th>Appt Date</th>
                          <th>Booking Date</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>    
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="name-box">
                              <figure className="image"><img src="assets/img/resource/dashboard-doc-1.png" alt="" /></figure>
                              <h5>Dr. Amelia Anna</h5>
                              <span className="designation">Cardiology</span>
                            </div>
                          </td>
                          <td>
                            <p>20 Oct 2020</p>
                            <span className="time">10:30AM</span>
                          </td>
                          <td>
                            <p>18 Oct, 2020</p>
                          </td>
                          <td>
                            <p>$150.00</p>
                          </td>
                          <td>
                            <span className="status">Confirm</span>
                          </td>
                          <td>
                            <span className="print"><i className="fas fa-print" />Print</span>
                          </td>
                          <td>
                            <span className="view"><i className="fas fa-eye" />View</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-box">
                              <figure className="image"><img src="assets/img/resource/dashboard-doc-2.png" alt="" /></figure>
                              <h5>Dr. Paolo Dybala</h5>
                              <span className="designation">Dermatology</span>
                            </div>
                          </td>
                          <td>
                            <p>20 Oct 2020</p>
                            <span className="time">10:30AM</span>
                          </td>
                          <td>
                            <p>18 Oct, 2020</p>
                          </td>
                          <td>
                            <p>$150.00</p>
                          </td>
                          <td>
                            <span className="status">Confirm</span>
                          </td>
                          <td>
                            <span className="print"><i className="fas fa-print" />Print</span>
                          </td>
                          <td>
                            <span className="view"><i className="fas fa-eye" />View</span>
                          </td>
                        </tr>
                        <tr className="cancel-block">
                          <td>
                            <div className="name-box">
                              <figure className="image"><img src="assets/img/resource/dashboard-doc-3.png" alt="" /></figure>
                              <h5>Dr. Samuel Daniels</h5>
                              <span className="designation">ACL Surgeon</span>
                            </div>
                          </td>
                          <td>
                            <p>20 Oct 2020</p>
                            <span className="time">10:30AM</span>
                          </td>
                          <td>
                            <p>18 Oct, 2020</p>
                          </td>
                          <td>
                            <p>$150.00</p>
                          </td>
                          <td>
                            <span className="status cancel">Cancelled</span>
                          </td>
                          <td>
                            <span className="print"><i className="fas fa-print" />Print</span>
                          </td>
                          <td>
                            <span className="view"><i className="fas fa-eye" />View</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-box">
                              <figure className="image"><img src="assets/img/resource/dashboard-doc-4.png" alt="" /></figure>
                              <h5>Dr. Julia Jhones</h5>
                              <span className="designation">Dentist</span>
                            </div>
                          </td>
                          <td>
                            <p>20 Oct 2020</p>
                            <span className="time">10:30AM</span>
                          </td>
                          <td>
                            <p>18 Oct, 2020</p>
                          </td>
                          <td>
                            <p>$150.00</p>
                          </td>
                          <td>
                            <span className="status">Confirm</span>
                          </td>
                          <td>
                            <span className="print"><i className="fas fa-print" />Print</span>
                          </td>
                          <td>
                            <span className="view"><i className="fas fa-eye" />View</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="name-box">
                              <figure className="image"><img src="assets/img/resource/dashboard-doc-5.png" alt="" /></figure>
                              <h5>Dr. Mary Astor</h5>
                              <span className="designation">Orthopedic</span>
                            </div>
                          </td>
                          <td>
                            <p>20 Oct 2020</p>
                            <span className="time">10:30AM</span>
                          </td>
                          <td>
                            <p>18 Oct, 2020</p>
                          </td>
                          <td>
                            <p>$150.00</p>
                          </td>
                          <td>
                            <span className="status pending">Pending</span>
                          </td>
                          <td>
                            <span className="print"><i className="fas fa-print" />Print</span>
                          </td>
                          <td>
                            <span className="view"><i className="fas fa-eye" />View</span>
                          </td>
                        </tr>
                      </tbody>    
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
	   <Footer />
    </div>
  );
};
