import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from "bootstrap";
const token = JSON.parse(localStorage.getItem('user-info-login')); 
//console.log(token);
///var id=(token.currentUser.uid !=null ? token.currentUser.uid:'');
let tooltipInstance = null;
export const Schedule = (props) => {  
//console.log(token);
const [appoinment, setAppoinment] = useState("");
const [count1, setCount1] = useState(0);
const [profile, setProfile] = useState([]);
  const fetchData2 = async() => {
    let id=(typeof token != 'undefined' && token != null ? (typeof token.currentUser != 'undefined' && token.currentUser != null ? token.currentUser.uid:token['id']):'');
    await fetch(`${process.env.REACT_APP_BASE_URL}/patient_appoinment/${id}`)
      .then(response => {
        return response.json()
      })
      .then(data2 => {
		  console.log(data2.data);
      //console.log(data2.data[0].count);
      setAppoinment(data2.data);
      let count11=((typeof data2.data != 'undefined' && data2.data!=null ? data2.data.length:0));
      //console.log(count11);
      //count11=count11;      
      //console.log(data2.data.length);     
      //console.log(data2.data[count1].count);
      //let count11=0;
      if(count11 > 0)
      {
      setCount1(data2.data[count11-1].count);  
      }
      //console.log(data2.data[count11-1].count);
      })
  }
  useEffect(() => {      
      fetchData2();
      
  }, [])
  const EventItem = ({ info }) => {   
    const { event } = info;
    //console.log(info.event.extendedProps.name); 
    return (
      <div>
        <p style={{color:'black',textAlign:'center'}}>{info.event.extendedProps.type}</p>
        <p style={{color:'black',textAlign:'center',marginTop:'-20px'}}>{info.event.extendedProps.name}</p>       
       <p style={{color:'green',textAlign:'center',fontSize:'20px',fontFamily:'Bold',marginTop:'-15px'}}>{event.title === 'Booked' ? 'Booked':''}</p>
       <p style={{color:'red',textAlign:'center',fontSize:'20px',fontFamily:'Bold',marginTop:'-10px'}}>{event.title === 'expire' ? 'Expire':''}</p>
       {info.event.extendedProps.type === 'Lab' ?
       <img className="eventimage" src={event.url}  alt={info.event.extendedProps.name}/>
       : <img className="eventimage1" src={event.url} alt={info.event.extendedProps.name} />
    }
      </div>
    );
  };
  
  const handleMouseEnter = (info) => {
    //console.log(info.event.extendedProps.description);
    if (info.event.extendedProps.description) {
      tooltipInstance = new Tooltip(info.el, {
        title: info.event.extendedProps.description,
        html: true,
        placement: "top",
        trigger: "hover",
        container: "body"
      });

      tooltipInstance.show();
    }
  };

  const handleMouseLeave = (info) => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
      tooltipInstance = null;
    }
  };
  return (
    <div>
	<Header />  
  <ToastContainer />
      <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/img/scheduletimming.png)`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Book Appoinment</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main> 
   <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
        </ol>
      </nav>
    </div>
  </div>
  <section className="doctors-dashboard bg-color-3">
        <div className="left-panel">
          <div className="profile-box patient-profile">
            <div className="upper-box">
              <figure className="profile-image"><img src={(typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null) ? token.currentUser.photoURL: ""} alt="" /></figure>
              <div className="title-box centred">
                <div className="inner">
                  <h3>{(typeof token.currentUser != 'undefined' && token.currentUser.email != null) ? token.currentUser.email:token['email']}</h3>
                  <p><i className="fas fa-calendar-alt" /></p>
                </div>
              </div>
            </div>
            <div className="profile-info">
            <ul className="list clearfix">
                <li><Link to="/dashboard" className="current"><i className="fas fa-columns" />Dashboard</Link></li>
                <li><Link to="/favorite_doctor"><i className="fas fa-heart" />Favourite Doctors</Link></li>                
                <li><Link to="/profile"><i className="fas fa-user" />My Profile</Link></li>
                <li><Link to="/change_password"><i className="fas fa-unlock-alt" />Change Password</Link></li>
                <li><Link href="/logout"><i className="fas fa-sign-out-alt" />Logout</Link></li>
              </ul>
            </div>
          </div>
        </div>        
        <div className="right-panel">
          <div className="content-container">
            <div className="outer-container">
            <FullCalendar
             plugins={[dayGridPlugin, interactionPlugin,listPlugin]}
             initialView="dayGridMonth"
             headerToolbar={{
              start: 'prev today next',
              center: 'title',
              right:'dayGridMonth,dayGridWeek,dayGridDay,listWeek'             
            }}
             eventMouseEnter={handleMouseEnter}
      eventMouseLeave={handleMouseLeave}
      eventContent={(info) => <EventItem info={info} />}
      events={appoinment}
           />		
              </div>
            </div>
          </div>       
      </section>   		
			</main>
	   <Footer />
    </div>
  );
};
