import React,{ useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import {ReadMore} from './ReadMore';
import axios from "axios";
import Parser from 'html-react-parser';
import Select from 'react-select';
import { useDispatch,useSelector } from 'react-redux';
import {arealist} from '../../features/counter/apiSlice';
import { fetchDataWithRetry } from "../../helper/formdata";
export const Lab = () => {  
  const [error, setError] = useState(false);
  const [lab, setLab] = useState([]);
  const [lab1, setLab1] = useState([]);
  const [selected1, setSelected1] = useState([]);
  //console.log(lab);
  const areaslistt=useSelector(arealist);
  useEffect(() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_lab`).then((lab) => {
        
      setLab(lab.data);
  
    }).catch((error) => {
   setError(error);
   });
  }, []);
  const handleOnChange1 = value1 => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    }
    else
    {
        setSelected1(value1);
    }
    //console.log(selected1);
  };
  const search = () => {
    //console.log(selected1.label);
    //console.log(clinic.filter((item) => selected === item.address2));
    if (selected1 !== null) {
      //console.log(hospital1.filter((item) => item.address2 === selected1.label));
      setLab(lab1.filter((item) => item.address2 === selected1.label));
      //setSelected(selected);
    }
    
    //console.log(selected);
  }; 
  return (
    <div>
	<Header />  
  <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="intro">
	    <div className="container" style={{ marginTop: `24px` }}>     
      
      <div className="row">
        <div className="col-md-12">
          <div className="intro-banner-search-form margin-top-95" style={{marginTop: '32%'}}>   
            
            <div className="intro-search-field with-autocomplete">
              <div className="input-with-icon">
			  <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"       
        labelledBy="Select City" 
        style={{backgroundColor: 'white',Color: 'white'}}   
        onChange={handleOnChange1}
        value={selected1} 
      />
                <i className=""></i> </div>
            </div>          
            
           
            <div className="intro-search-button">
			<button type="submit" onClick={search} className="button ripple-effect">Search</button>
            </div>
          </div>
        </div>
      </div>
      
      </div>
            </div>
   <br/>
   <main>

          <section className="inner_content block">
<div className="container">
<div className="row">

<div className="col-sm-12">
{typeof lab != 'undefined' && lab.length > 0 && lab.map((item) => {
				   const { id,lab_image,lab_title,lab_desc,include } = item;
           //console.log(include);
				   return (
					<div key={id}>
  <div className="portfolio_body_n" id="ransom_randolph">
<div className="portfolio_card_n">
 <figure><img className="eventimage" src={lab_image} /></figure>
 <article>
  <span><img src={lab_image} /></span>
  
   <p className='lab_facilities'><strong>Facilities</strong>  <ReadMore>{include}</ReadMore></p>
   <p>{lab_desc}, please visit <strong> <a href="http://www.booklabonline.com" target="_blank">www.booklabonline.com.</a></strong></p>
 </article>
</div>
</div>
</div>
					 );
					})} 
</div>

</div>
</div>
</section>
   </main>
	   <Footer />
    </div>
  );
};
