import { useState} from "react";
import { Link } from "react-router-dom";
export const DashboardLeft = () => {
    const token = JSON.parse(localStorage.getItem('user-info-login'));
    console.log(token);
    const [selectedFile, setSelectedFile] = useState("");
    return (	
  <>
   <div className="left-panel">
          <div className="profile-box patient-profile">
            <div className="upper-box">
              <figure className="profile-image"><img src={selectedFile.length === 0  ? (typeof token.currentUser != 'undefined' && token.currentUser.photoURL != null ? token.currentUser.photoURL: "") : selectedFile} alt="" /></figure>
              <div className="title-box centred">
                <div className="inner">
                  <h3>{(typeof token.currentUser != 'undefined' && token.currentUser.email != null) ? token.currentUser.email:token['email']}</h3>
                  <p><i className="fas fa-calendar-alt" /></p>
                </div>
              </div>
            </div>
            <div className="profile-info">
            <ul className="list clearfix">
                <li><Link to="/dashboard" className="current"><i className="fas fa-columns" />Dashboard</Link></li>
                <li><Link to="/order"><i className="fa fa-heart" />Order</Link></li>                
                <li><Link to="/profile"><i className="fa fa-user" />My Profile</Link></li>
                <li><Link to="/change_password"><i className="fa fa-unlock-alt" />Change Password</Link></li>
                <li><Link href="/logout"><i className="fa fa-sign-out-alt" />Logout</Link></li>
              </ul>
            </div>
          </div>
        </div>
  </>
  );
};