import React,{ useEffect, useState,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { getDistance, getPreciseDistance,geolib,findNearest,getAreaOfPolygon } from 'geolib';
import Parser from 'html-react-parser';
import {useAuthValue} from './AuthContext';
import { FaStar } from "react-icons/fa";
import { useDispatch,useSelector } from 'react-redux';
import{currentlatitude,currentlongitude} from '../../features/counter/apiSlice';
export const Medicose = (props) => { 
  const [medical, setMedical] = useState([]); 
  const lt = useSelector(currentlatitude);
  const lng = useSelector(currentlongitude); 
  const url1 = async () => { 
    if(lt !='' && lng !='')
    {
   await fetch(`${process.env.REACT_APP_BASE_URL}/neary_by/${lt}/${lng}`)
  .then(function (response) {    
    if (!response.ok) {
        return Promise.reject('some reason');
    }

    return response.json();

})
  .then(res => {
    var places = [];
     //console.log(res.data.results);
    for (let googlePlace of res.data.results) {
      var place = {};
      
      var myLat = googlePlace.geometry.location.lat;
      var myLong = googlePlace.geometry.location.lng;
      var coordinate = {
        latitude: myLat,
        longitude: myLong,
      };               
      place['placeTypes'] = googlePlace.types;
      place['link'] = (googlePlace.photos !='' && googlePlace.photos !=undefined && googlePlace.photos !=null) ? googlePlace.photos[0].html_attributions :'';
      place['vicinity'] = googlePlace.vicinity;
      place['open_now'] = (googlePlace.opening_hours !='' && googlePlace.opening_hours !=undefined && googlePlace.opening_hours !=null && googlePlace.opening_hours.open_now == true) ? 'Open':'Closed';
      place['coordinate'] = coordinate;
      place['placeId'] = googlePlace.place_id;
      place['placeName'] = googlePlace.name;
      place['icon'] = googlePlace.icon;
      place['rating'] = googlePlace.rating;
      place['reviews'] = googlePlace.reviews;
      place['number'] = googlePlace.number;
      place['website'] = googlePlace.website;
      place['business_status'] = googlePlace.business_status;
      place['lat'] = myLat;
      place['long'] = myLong;
      //console.log(place);
      places.push(place);
      //console.log(places);
     
    }
    //console.log(places);
    // Show all the places around 4 km from San Francisco.
    setMedical(places);    
  })
  .catch(error => { 
    console.log(error);
  });
}
}  
useEffect
(() => {  
  url1();    
    //fetchData2();
}, [lt,lng])
    const openLinkInNewTab = ( url ) => {
      const newTab = window.open(url, '_blank', 'noopener,noreferrer');
      if ( newTab ) newTab.opener = null;
    }
    const [medical1, setMedical1] = useState([medical]); 
  return (
    <div>
	<Header />  
  
      <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(https://www.arcticcoolsys.com/images/ihead-4.jpg)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Near By Medicose</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  
   <section className="clinic-section">
          <div className="auto-container">
            <div className="row clearfix">
              <div className="col-lg-10 col-md-12 col-sm-12 content-side">
                <div className="item-shorting clearfix">
                  
                  <div className="right-column pull-right clearfix">                    
                    <div className="menu-box">
                      <button className="list-view on"><i className="icon-List" /></button>
                      <button className="grid-view"><i className="icon-Grid" /></button>
                    </div>
                  </div>
                </div>
                <div className="wrapper list">
                  <div className="clinic-list-content list-item">                   
                    {typeof medical != 'undefined' && medical.length > 0 && medical && (
						<>
						{medical.map((item) => {
				    let dis=getDistance(               
              { latitude: item.lat, longitude: item.long },
              { latitude: lt, longitude: lng }
                 );	
                 const background=item.background;
                 var url = "https://www.google.com/maps/dir/?api=1";
var origin = "&origin=" + lt + "," + lng;
var destination = "&destination=" + item.lat + "," + item.long;
var newUrl = new URL(url + origin + destination);
let ratings=item.rating;
//var photo_url = "https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference="+item.photo_reference+"&key=AIzaSyC0JhCZR3ilmCXiacjW4ME1Nd72lW76D_g";
//console.log(photo_url);
				   return (
				   <div key={item.placeId}>
                    <div className="clinic-block-one">
                      <div className="inner-box">
                        <div className="pattern">
                          <div className="pattern-1" style={{backgroundImage: 'url(assets/img/shape/shape-24.png)'}} />
                          <div className="pattern-2" style={{backgroundImage: 'url(assets/img/shape/shape-25.png)'}} />
                        </div>
                        <figure className="image-box" style={{width:'32%'}}><img src={item.icon} alt="" style={{background}}/></figure>
                        <div className="content-box" style={{marginLeft:'200px'}}>
                          <div className="like-box"><a href="clinic-details.html"><i className="fa fa-heart" /></a></div>
                          <ul className="name-box clearfix">
                            <li className="name"><h3><a href="clinic-details.html">{Parser(item.placeName)}</a></h3></li>
                            <li><i className="icon-Trust-1" /></li>
                            <li><i className="icon-Trust-2" /></li>
                          </ul>
                          <span className="designation">Best healthcare center</span>
                          <div className="text">
                          
                          
                          </div>
                          <div className="rating-box clearfix">
                            <ul className="rating clearfix">
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><i className="icon-Star" /></li>
                              <li><a href="clinic-details.html">(21)</a></li>
                            </ul>
                            <div className="link"><a href="clinic-details.html">24/7 Available</a></div>
                          </div>
                          <div className="location-box">
                          <p><i className="fa fa-map-marker">{item.vicinity}</i></p>
                          <div dangerouslySetInnerHTML={{__html: item.link}} />
                          <p>{item.number}</p>
                          <p>{item.business_status}</p>
                          <p>{item.open_now}</p>
                          <p>{item.website}</p>
                          {[...Array(5)].map((star, i) => {
                 const ratingValue = i + 1;                 
                return (                
                  <FaStar
              className="star"
              color={(i < item.rating) ? "gold" : "lightgray"}
              size={20} key={i}             
            />           
                        );
                    })}
                          <p>{item.reviews}</p>
                          <p><i className="fa fa-map-marker">{`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`}</i></p>
                          <button onClick={ () => openLinkInNewTab(newUrl)}> Direction </button>
                          </div>
                       
                        </div>
                      </div>
                    </div>
                    </div>
						);
          })}
						</>
						)}
                  </div>                  
                </div>                
              </div>              
            </div>
          </div>
        </section>       
      </main>
	   <Footer />
    </div>
  );
};
   