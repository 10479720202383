import useFormContext from "../hooks/useFormContext"
import Form from './Form';
const Billing = () => {
  const { data, handleChange } = useFormContext()
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
    const content = (
        <section className="personal_info">
        <div className="login-box">
  <h2>Personal Information</h2>
    <div className="user-box">
    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="FirstName"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={(token != null) ? token['name'] : data.name}
                        onChange={handleChange}
                        required=""
                    />
      <label>Full Name</label>
    </div>
    <div className="user-box">
    <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="email"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={(typeof token != 'undefined' && token != null) ? token.currentUser.email:data.email}
                        onChange={handleChange}
                    />
      <label>Email</label>
    </div>
    <div className="user-box">
    <input
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="phone"
                        pattern="([A-Z])[\w+.]{1,}"
                        value={data.phone}
                        onChange={handleChange}
                    />
      <label>Phone Number</label>
    </div>
    {(token == null) ?
                                         <div className="user-box">
                                            <input type="password" name="password" placeholder="Password"   onChange={handleChange} required="required"/>
                                            <label>Password</label>
                                        </div>
                                        : ''}
      <Form/>
   
</div>
</section>
    )

    return content
}
export default Billing