import React,{ useEffect, useState } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import store from '../../app/store';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch,useSelector } from 'react-redux';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
export const AirAmbulance = (props) => { 
  let statevalue=useSelector((state) => state); 
  //console.log(statevalue);
  const dispatch=useDispatch();
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  //console.log(token);
    const [message, setMessage] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    
    const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:'',register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'air'})
   
    const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
    const handleSubmit=async(e)=>
    {
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
    // useEffect(() => {  
    //   //props.ChangeId(labid1);  
    //   props.ChangeType('air');  
    //   props.ChangeId('4'); 
    // }, [])
  return (
    <div>
	<Header />  
  <ToastContainer />
  <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/img/dreaming-young-girl-looking-sky.jpg)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Air Ambulance</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>     
    </div>
   <br/>
   <main>
  <div className='about-inner'>
  <div className='container'>
  <div className='row'>
  <div className='col-md-12'>
   <div className="col-md-9 cntleft">
        <article id="post-35" className="post-35 post type-post status-publish format-standard has-post-thumbnail hentry category-services">
          <div className="post-thumbnail">
           	</div>
          <header className="entry-header"><h1 className="entry-title">Air Ambulance</h1></header>
          <div className="entry-content">
            <p><strong>Vedanta Air Ambulance</strong> provides an affordable and advanced <strong>Air Ambulance Services</strong> in all over India for transporting an ICU and other critical patients from one city to another city. We are fastest growing air ambulance service provider of northern India, in fact, our both charter and commercial air ambulances are available worldwide for patient transportation purpose.</p>
            <p><a href="https://www.vedantaairambulance.com"><strong>Air Ambulance Cost by Vedanta</strong></a> is very affordable and this service will be always less expensive than other air ambulance service in India. Another best thing is that our services are best, quick and advanced than any other air ambulance from India. Vedanta Air Ambulance provides best services within time and without charging huge money. So never thing about anything and don’t be panic about rate as you are going to avail India’s best and world class air ambulance services.</p>
          </div>
        </article>
        <img width={400} height={210} src="https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1.jpg" className="attachment- img-responsive size- img-responsive wp-post-image" alt="" srcSet="https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1.jpg 400w, https://www.vedantaairambulance.com/wp-content/uploads/2017/05/air-ambulance-1-300x158.jpg 300w" sizes="(max-width: 400px) 100vw, 400px" />
      </div>      
      <div className="col-md-3 comment-bottom">       
      <form onSubmit={handleSubmit}>
      <input className="with-border" name="name" value={(token != null) ? token['name'] : inputValue.name} type="text" id="name" placeholder="Your Name" onChange={handleInput} required="required" />
      <input className="with-border" name="email" value={(typeof token != 'undefined' && token != null) ? token.currentUser.email:inputValue.email} type="email" id="email" placeholder="Email Address" onChange={handleInput} required="required" />
      <input className="with-border" name="phone"  value={(token != null) ? token['phone'] : inputValue.phone} type="number" id="phone" placeholder="Your Phone Number" onChange={handleInput} required="required" /> 
      {(token == null) ?                                      
      <input type="password" name="password" placeholder="Password"  value={inputValue.password} onChange={handleInput} required="required"/>
                                       
                                        : ''}   
      <div className="form-group">
      <DatePicker selected={startDate} name="startDate1" onChange={(date) => setStartDate(date)} minDate={startDate} value={inputValue.startDate1}/>
      <i className="fa fa-calendar-alt"></i>
      </div>                                   
      <input className="with-border" name="subject" value={inputValue.subject} onChange={handleInput} type="text" id="subject" placeholder="Subject" required="required" />   
      <textarea className="with-border" name="comments" value={inputValue.comments} onChange={handleInput} cols="40" rows="5" id="comments" placeholder="Query"  required="required"></textarea>     
      <input type="submit" className="submit button margin-top-15" id="submit" value="Submit Message" />                
           
            {props.message.length > 0 ?
            <div className="message contact-address-headline success">{props.message ? <p>{props.message}</p> : null}</div>
             :''}
          </form> 
            </div>
        </div>
        </div>
        </div>
      </div>
   </main>
	   <Footer />
    </div>
  );
};
