import "bootstrap/dist/css/bootstrap.css";
import { Header } from "./Header";
import { Footer } from "./Footer";
import React, { useEffect, useState } from "react";
import { useNavigate} from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import {  toast } from 'react-toastify';
import{user_register} from '../../features/counter/apiSlice';
import { AccountErrors } from "../../validation/account";
export const Register = () => {
  const navigate = useNavigate();
  const { status, user } = useSelector((state) => state.api);
  const [msg, setMsg] = useState("");
  const [inputValue,setInputValue]=useState({name:'',email:'',phone:'',password:''})
  const dispatch=useDispatch();
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
  const handleSubmit = async (e) => {
	//console.log('submit');
    e.preventDefault();
    const result =await dispatch(user_register(inputValue));
    if (user_register.fulfilled.match(result)) {
    const wait = toast.loading("Please wait...");
			toast.update(wait, {
				render: `Welcome back ${result.meta.arg.email}`,
				type: "success",
				isLoading: false,
        autoClose: 3000,
				hideProgressBar: false,
				progress: undefined,
				closeOnClick: true,
				pauseOnHover: true,
			  });
       // navigate('/dashboard');
		   // Redirect to the dashboard on successful login
    }
    if (user_register.rejected.match(result)) {
      const wait = toast.loading("Please wait...");
      toast.update(wait, {
      render: AccountErrors(result.payload),
      type: "error",
     isLoading: false,
     autoClose: 3000,
     closeOnClick: true,
      pauseOnHover: true,
});
}
  };
useEffect(()=>
{
  if (status == 'succeeded') {
    navigate('/dashboard'); // Redirect to the desired component after successful registration
    //dispatch(reset());
  }
  
},[status])
  return (
    <div>
      <Header />
      <div className="banner">
        <div className="banner-item banner-bg inner_banner">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="banner-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="login">
        <div className="register_block">
          <div className="row">
            <div className="col-sm-6">
              <img
                src="assets/img/register_img.jpg"
                className="img-responsive"
                alt="register_img.jpg"
              />
            </div>
            <div className="col-sm-6">
              <div className="login-register-page">
                <div className="welcome-text">
                  <h3>Let's create your account!</h3>
                  <span>
                    Already have an account? <a href="signup.php">Log In!</a>
                  </span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-user"></i>
                    <input
                      type="text"
                      className="input-text with-border"
                      onChange={handleInput}
                      name="name"
                      id="name"
					  value={inputValue.name}
                      placeholder="Name"
                      required=""
                    />
                  </div>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-envelope"></i>
                    <input
                      type="email"
                      className="input-text with-border"
					  onChange={handleInput}
                      name="email"
					  value={inputValue.email}
                      id="emailaddress"
                      placeholder="Email Address"  
                    />
                  </div>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-phone"></i>
                    <input
                      type="phone"
                      onChange={handleInput}
					  value={inputValue.phone}
                      className="input-text with-border"
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                      required=""
                    />
                  </div>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-lock"></i>
                    <input
                      type="password"
                      className="input-text with-border"
                      name="password"
					  value={inputValue.password}
                      id="password"
                      placeholder="Password"
					  onChange={handleInput}
                      required=""
                    />
                  </div>
                  <button type="submit" className="button"  disabled={status === 'loading'}>
          {status === 'loading' ? 'Loading...' : 'Sign Up'}
        </button>
                    
                </form>
                <div className="social-login-separator">
                  <span>or</span>
                </div>
                <div className="social-login-buttons">
                  <button className="facebook-login ripple-effect">
                    <i className="icon-brand-facebook-f"></i> Register via
                    Facebook
                  </button>
                  <button className="google-login ripple-effect">
                    <i className="icon-brand-google-plus-g"></i> Register via
                    Google+
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
