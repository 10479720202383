import React,{ useEffect, useState,useMemo } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import Parser from 'html-react-parser';
import axios from "axios";
export const HealthPackage = (props) => {  
  //console.log(props.lab);
  const [testimonial, setTestimonial] = useState([]);
  const [center, setCenter] = useState([]);
  const [health, setHealth] = useState([]);
  const [activeTab, setActiveTab] = useState('2'); 
  const [lab, setLab] = useState([]);
useMemo(() => {		
  const data = localStorage.getItem('lab');
  if (data) {
    setLab(JSON.parse(data));
  }
  else
  {
  axios
  .get(`${process.env.REACT_APP_BASE_URL}/home_lab`)
  .then((response) => { 
    localStorage.setItem('lab', JSON.stringify(response.data.data));
    setLab(response.data.data); });	
  }		
}, []) 
  useEffect(() => {    
    fetchData1();	
  }, []);
  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab = (e) => {
    //console.log(e.currentTarget.dataset.id);
    // update the state to tab1
    setActiveTab(e.currentTarget.dataset.id);
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };
  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
  };
  const handleTab4 = () => {
    // update the state to tab2
    setActiveTab("tab4");
  }; 
const fetchData1 = async() => {

await fetch(`${process.env.REACT_APP_BASE_URL}/health_list1`)
  .then(response => {
    return response.json()
  })
  .then(data1 => {
      
    setHealth(data1.data)
    //console.log(category);
  })
}
  
  return (
    <div>
	<Header />  
  
      <div className="banner">	  
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(assets/img/bg1.png)`,marginTop: `92px`
    }}>
            <div className="d-table">			
                <div className="d-table-cell">			
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>HEALTH CHECKS & LAB TESTS</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <br/>
   <main>  				
   <div className="section innerblock">
   
        <div className="container clinic-details-content doctor-details-content">
          <div className="listings-container grid-layout">
          <div className="tabs-box">
                  <div className="tab-btn-box centred">
                    <ul className="tab-btns tab-buttons clearfix">
                    {lab.map((item,i) => {
				   const { id, lab_title} = item;
				   return (
                      <li key={id}  className={activeTab === id ? "tab-btn active-btn" : ""} data-id={id} onClick={handleTab}>{lab_title}</li>
                    );
                  })}
                    </ul>
                  </div>
                  <div className="tabs-content">
                  {lab.map((item,i) => {
				   const { id, lab_title} = item;
				   return (
                  <div key={id} className={activeTab === id ? "tab active-tab" : ""} id={id}>
                      <div className="inner-box">
                        <div className="text">   
                        {health.map((item,i) => {
				   const { lab_id, lab_title, health_market_price, health_sell_price,include,discount,health_desc,image,health_ids,health_title } = item;
           if(lab_id === activeTab && id !== activeTab)
           {
            
				   return (
				   
            <Link to="/health_details"  state={{ids: health_ids}} className="lab-listing" key={i}>
              <div className="lab-listing-details">
                <div className="lab-listing-company-logo">
                  <img src={image} alt="" />
                </div>
                <div className="lab-listing-description">
                  <h3 className="lab-listing-title">{health_title}</h3>
                  {(health_desc)}
                  <h5>Test Include</h5>
                                    <ul>
									{typeof include != 'undefined' && include.length > 0 && include && (
									<div>
                   
									{include.map((item1,j) => {
                    //console.log(health_ids);
                    
										return (
                                        <li key={j}><i className="fa fa-check-circle"></i><br></br>{item1.lab_cat_title}</li>
                                        );
                                     
										})}
										</div>
										)}
                                    </ul>
                </div>
              </div>
              <div className="lab-listing-footer"> <span className="bookmark-icon" />
                <ul>
                  <li>Offer Price</li>
                  <li><span><i className="fa fa-rupee" />{health_market_price}</span><i className="fa fa-rupee" />{health_sell_price}</li>
                </ul>
              </div>
            </Link>
            
				   );
          }
          })}
                        </div>                       
                        
                      </div>
                    </div>
);
})}

                  </div>
                </div>
        
          </div>         
        </div>
        </div>			
			</main>
	   <Footer />
    </div>
  );
};
