import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  signIn,
  facebooklogin,
  googlelogin,
  signWithPhoneNumber,
  verifyflag,
  verifyOTP,
} from "../../features/counter/apiSlice";
import { AccountErrors } from "../../validation/account";
export const Login = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [inputValue, setInputValue] = useState({ email: "", password: "" });
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const verifyflagstatus = useSelector(verifyflag);
  const { status, user } = useSelector((state) => state.api);
  const handleInput = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    //console.log(inputValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(signIn(inputValue));
    const wait = toast.loading("Please wait...");
    if (signIn.fulfilled.match(result)) {
      toast.update(wait, {
        render: `Welcome back ${result.meta.arg.email}`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
        hideProgressBar: false,
        progress: undefined,
        closeOnClick: true,
        pauseOnHover: true,
      });
      navigate("/dashboard"); // Redirect to the dashboard on successful login
    }
    if (signIn.rejected.match(result)) {
      toast.update(wait, {
        render: AccountErrors(result.payload),
        type: "error",
        isLoading: false,
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };
  return (
    <div>
      <Header />
      <div className="banner">
        <div className="banner-item banner-bg inner_banner">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="banner-content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="login">
        <div className="register_block">
          <div className="row">
            <div className="col-sm-6">
              <img
                src="assets/img/register_img.jpg"
                alt="Register"
                className="img-responsive"
              />
            </div>
            <div className="col-sm-6">
              <div className="login-register-page">
                <div className="welcome-text">
                  <h3>We're glad to see you again!</h3>
                  <span>
                    Don't have an account? <Link to="/">Sign Up!</Link>
                  </span>
                </div>
                <div className="messages">
                  <span style={{ color: "red" }}></span>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-envelope"></i>
                    <input
                      type="text"
                      className="input-text with-border"
                      name="email"
                      onChange={handleInput}
                      id="emailaddress"
                      placeholder="Username"
                    />
                  </div>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-lock"></i>
                    <input
                      type="password"
                      className="input-text with-border"
                      name="password"
                      onChange={handleInput}
                      id="password"
                      placeholder="Password"
                    />
                  </div>
                  <Link to="/forgetpassword" className="forgot-password">
                    Forgot Password?
                  </Link>
                  <div className=" social-login-separator">
                    <span>or</span>
                  </div>
                  <button
                    type="submit"
                    className="button"
                    disabled={status === "loading"}
                  >
                    {status === "loading" ? "Logging in..." : "Login"}
                  </button>

                  <button type="submit" className="button" form="login-form">
                    Log In
                  </button>
                </form>
                <form style={{ display: !verifyflagstatus ? "block" : "none" }}>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-envelope"></i>
                    <input
                      type="text"
                      className="input-text with-border"
                      name="phone"
                      onChange={(e) => setPhone(e.target.value)}
                      id="phonenumber"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div
                    id="recaptcha-container"
                    className="justify-center flex"
                  ></div>
                  <button
                    onClick={() => dispatch(signWithPhoneNumber(phone))}
                    className="button"
                    type="submit"
                    form="login-form"
                  >
                    Send
                  </button>
                </form>
                <form style={{ display: verifyflagstatus ? "block" : "none" }}>
                  <div className="input-with-icon-left">
                    {" "}
                    <i className="fa fa-envelope"></i>
                    <input
                      type="text"
                      className="input-text with-border"
                      name="code"
                      onChange={(e) => setCode(e.target.value)}
                      id="Otp"
                      placeholder="One Time Password"
                    />
                  </div>
                  <button
                    onClick={() => dispatch(verifyOTP(code))}
                    className="button"
                    type="submit"
                    form="login-form"
                  >
                    Send
                  </button>
                </form>
                <div className="social-login-separator">
                  <span>or</span>
                </div>
                <div className="social-login-buttons">
                  <button
                    onClick={() => dispatch(googlelogin())}
                    className="button google-login ripple-effect"
                    type="submit"
                    form="login-form"
                  >
                    Sign In with Google
                  </button>
                  <button
                    onClick={() => dispatch(facebooklogin())}
                    className="button facebook-login ripple-effect"
                    type="submit"
                    form="login-form"
                  >
                    Sign In with Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
