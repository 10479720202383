import React,{ Fragment,useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import Paginate from './Paginate'; 
import { fetchDataWithRetry } from "../../helper/formdata";   
export const Blog = (props) => {
    const [article, setArticle] = useState([]);
    const [articlelist, setArticlelist] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(5);
   const [error, setError] = useState(false);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = articlelist.slice(indexOfFirstPost, indexOfLastPost);
	//console.log(articlelist);
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
}  
    //const [justslug, setJustslug] = useState("");
    
      
    function handleClick(slug)
    {
        const currentPosts = articlelist.slice(indexOfFirstPost, indexOfLastPost);
    //setJustslug(slug.art_cat_slug);
    fetchData2(slug.art_cat_slug);
    }
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/article_cat_list`).then((article) => {
        setArticle(article.data);
    
      }).catch((error) => {
     setError(error);
     });
//   const fetchData1 = async() => {
//     await fetch(`${process.env.REACT_APP_BASE_URL}/article_cat_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data1 => {
		  
//         setArticle(data1.data)
// 		//console.log(category);
//       })
//   }
  
  const fetchData2 = async(props) => {
   //console.log(props);
    let url='';
    if (props === undefined) {
        
    url=`${process.env.REACT_APP_BASE_URL}/article_list`;
    }
    else
    {
        
        
    url=`${process.env.REACT_APP_BASE_URL}/article_list/${props}`;
    }
    fetchDataWithRetry(url).then((article) => {
        setArticlelist(article.data);
    
      }).catch((error) => {
     setError(error);
     });

  }
  useEffect(() => {    
    fetchData2();
  
  }, []);
  
  return (
    <div>
	<Header />
       <div className="clearfix"></div>
       <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  <div className="section innerblock">
    <div className="container">
    <section className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="col-lg-8 col-md-12 col-sm-12 content-side">
                        <div className="blog-standard-content">
                        {typeof articlelist != 'undefined' && articlelist.length > 0 && articlelist && (
						<>
						{currentPosts.map((item, i) => {
				   const { id, art_title,art_banner} = item;
				   return (
                           <div key={id}>
                            <div className="news-block-one">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src={art_banner} alt=""/>
                                        <Link to={`/blog/${id}`} className="link"><i className="fa fa-link"></i></Link>
                                        <span className="category">Featured</span>
                                    </figure>
                                    <div className="lower-content">
                                        <h3><Link to={`/blog/${id}`} style={{ textDecoration: 'none' }}>{art_title}</Link></h3>
                                        <ul className="post-info">
                                            <li><img src="assets/img/sandeep-modified.png" alt=""/><a href="index-2.html">Sandeep Sahu</a></li>
                                            <li>April 23, 2020</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisic do eiusmod tempor incididunt labore dolore magna aliqua enim minim veniam. quis nostrud exercitation.</p>
                                        <div className="link"><Link   to={`/blog/${id}`}><i className="fa fa-arrow-right"></i></Link></div>
                                        <div className="btn-box"><Link   to={`/blog/${id}`}   className="theme-btn-one">Read more<i className="fa fa-arrow-right"></i></Link></div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            );
                        })}
                                      </>
                                      )}
                            
                        </div>
                        <Paginate
                  postsPerPage={postsPerPage}
                  totalPosts={articlelist.length}
                  paginate={paginate}
               />
                    </div>
                    
                    <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
                        <div className="blog-sidebar">
                          
                            <div className="sidebar-widget category-widget">
                                <div className="widget-title">
                                    <h3>Categories</h3>
                                </div>
                                <div className="widget-content">
                                    <ul className="category-list clearfix">
                                    {typeof article != 'undefined' && article.length > 0 && article && (
						<>
						{article.map((item, i) => {
				   const { id, art_cat_title, art_cat_slug} = item;
				   return (
				   
                    
                                        <li key={id}><Link  onClick={() => handleClick({art_cat_slug})} style={{ textDecoration: 'none' }}>{art_cat_title}</Link></li>
                                        
                                       
						);
          })}
						</>
						)}
                                    </ul>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
       
    </div>
  </div>
	   <Footer />
    </div>
  );
};
