import React,{ useEffect, useState } from 'react';
import { Link,Route } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { ProductDetails } from './ProductDetails';
export const Product = (product,j=1) => {
    //console.log(product);
return (
    <>
    <div key={j} className="single_product grow">
    <div className="product_image_new">
                      <Link to="" title="myUpchar Ayurveda Urjas Massage Oil For Men">
                        <img className="product_image" src={product.item.pi_name} alt="myUpchar Ayurveda Urjas Massage Oil For Men" loading="lazy" />
                      </Link>
                    </div>
                    <div className="product_details1">
                      <Link to="" title="myUpchar Ayurveda Urjas Massage Oil For Men"><h3><strong className="text_hover">{product.item.product_name}</strong></h3></Link>
                      <span>
                        30 ml Oil in 1 Bottle
                      </span>
                      <span><strong>₹399</strong>
                        <u>₹449</u><small className="home_offer btn">11% off</small>
                      </span>
                    </div>
                    <div className="see_medicine">
                      <Link key={product.item.product_id} to={`/productdetails/${product.item.product_id}`} style={{color: '#F56927'}}><strong>Details</strong></Link>
                    </div>     
      </div>      
    </>
  );
}