import React, { useState,useContext } from "react";
import "bootstrap/js/src/collapse.js";
import { NavLink,useNavigate,Link } from "react-router-dom";
import { signOut } from 'firebase/auth' 
import { CartContext } from '../../context/cart';
import { auth } from './firebase';
import { useDispatch,useSelector } from 'react-redux';
import {currentcity} from '../../features/counter/apiSlice';
export const Header = () => {
const [click, setClick] = useState(false);
const navigate = useNavigate(); 
const token = localStorage.getItem('user-info-login');
const { cartItems,quantity,total1} = useContext(CartContext);
const city=useSelector(currentcity);
const logOut = async () => {
    await auth.signOut().then(console.log("signed out."));
    localStorage.clear('user-info-login');
    navigate('/');
}
  const handleClick = () => setClick(!click);  
  const logout = (e) => {
    e.preventDefault();
    //console.log('Logout');

    // CLEAR DATA FROM STORAGE
    localStorage.clear();
    sessionStorage.clear();

    navigate("/");
}
const navLinkStyles = ({isActive}) => {
    return {        
        color: isActive ? "red" : "white"        
    };
};
//console.log(auth.currentUser.email);
return(
   <div>
   <div className="header-top">
        <div className="container">
            <div className="row">
                <div className="top-bar">
                    <ul>
                        <li><Link to="/" onClick={handleClick}><i className="fa fa-phone"></i>+2(305) 587-3407</Link></li>
                        <li><Link to="/" onClick={handleClick}><i className="fa fa-envelope"></i>info@example.com</Link></li>
                    </ul>
                    <div className="header_right clearfix">
                    <div className="header_contact">
                <ul>
                {
          total1 > 0 ? (
                    <li><Link className='px-4 py-2 bg-gray-800 text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700' exact='true' to="/cart"><i className="fa fa-shopping-cart">Cart ({total1})</i></Link></li>
                    ) : (
                        ''
                      )
                    }
                  <li><Link exact='true' to="/digital"><button type="button" className="btn btn">{(city !=undefined ? city.label:'')}<img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/668635.png"} style={{"height" : "40px"}} /></button></Link>  
                    {token && token!=null  ? (
                    <button onClick={logOut} className="btn login-btn">Logout</button>
                    ) : (                            
                            <Link exact='true' to="/login" onClick={handleClick}><button type="button" className="btn login-btn">Login</button></Link>    
                            )}</li>  
                            <li>  
                    {token && token!=null  ? (
                    <Link exact='true' to="/dashboard"><button type="button" className="btn btn"><img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/images (1).png"} style={{"height" : "40px"}} /></button></Link>
                    ) : (                            
                          <div style={{display: 'none' }}></div>  
                            )} </li> 
                                                     
                </ul>
              </div>
              </div>
                    
                </div>
            </div>
        </div>
    </div>
     <header>
        <div className="header-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg">
                            <Link className="navbar-brand"  exact='true' to="/">
                                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/logo.png"} alt="Logo" style={{ maxWidth: "52%" }} className="img-fluid" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fa fa-align-right"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item">
                                        <NavLink  style={navLinkStyles} exact='true' to="/"  className="nav-link" onClick={handleClick}>Home</NavLink>
                                    </li>
                                    
                                    <li className="nav-item">
                                        <NavLink  style={navLinkStyles} exact='true' to="/hospital"  onClick={handleClick} className="nav-link">Hospitals</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/lab"  onClick={handleClick} className="nav-link">Our Labs</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/specialist"  onClick={handleClick} className="nav-link">Doctor</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/contact"  onClick={handleClick} className="nav-link">Contact Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/team"  onClick={handleClick} className="nav-link">Clients</Link>
                                    </li>
									<li className="nav-item">
                                        <Link  exact='true' to="/about"  onClick={handleClick} className="nav-link">About Us</Link>
                                    </li>
									
									<li className="nav-item">
                                        <Link  exact='true' to="/blog"  onClick={handleClick} className="nav-link">Blog</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/how-it-works"  onClick={handleClick} className="nav-link">How It Works</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link  exact='true' to="/partner"  onClick={handleClick} className="nav-link">Our Partners</Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </div> 
);

}

