import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "react-bootstrap-time-picker";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { MultiSelect } from "react-multi-select-component";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import{signUp} from '../../features/counter/apiSlice';
import { booking1 } from "../../helper/formdata";
import axios from "axios";
import { getPreciseDistance } from "geolib";
import { useDispatch, useSelector } from "react-redux";
import {  arealist,currentcity } from "../../features/counter/apiSlice";
export const Labs = () => {
  const navigate = useNavigate();
  const areaslistt = useSelector(arealist);
  const city=useSelector(currentcity);
  const token = JSON.parse(localStorage.getItem("user-info-login"));
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState(city);
  const [searchlab, setSearchLab] = useState([]);
  const [labid, setLabId] = useState([]);
  const [labid1, setLabId1] = useState([]);
  const { state } = useLocation();
  const [labcategory, setLabCategory] = useState([]);
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:'',subject:'',comments:'',age:'',gender:'',time:'',reference_id:labid1,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'lab',parameter:selected})
  const [time, setTime] = useState('10:00');
  const handleClose = () => setShow(false);
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [lt, setLt] = useState("");
  const [lng, setLng] = useState("");
  const datechange=(date)=>
    {
      //console.log(date);
      setStartDate(date);
      setInputValue({...inputValue,startDate:date});
       
    }
    const handleInput1=(time)=>
      {
        console.log(time);
        setInputValue({...inputValue,time:time});
        //setTime(time); 
      }
  //console.log(token);
  const openLinkInNewTab = (url) => {
    const newTab = window.open(url, "_blank", "noopener,noreferrer");
    if (newTab) newTab.opener = null;
  };
  navigator.geolocation.getCurrentPosition(function (position) {
    setLt(position.coords.latitude);
    setLng(position.coords.longitude);
  });
 
  //console.log(areaslistt);
  const dispatch = useDispatch();
  const handleInput=(e)=>
    {
      
      setInputValue({...inputValue,[e.target.name]:e.target.value});
      //console.log(inputValue);
    }
    const handleSubmit=async(e)=>
    {
      //console.log('test');
      e.preventDefault();
      if(token === null)
        {
          dispatch(signUp(inputValue));
          
        }
        else
        {
          //console.log(labid1);
          //console.log(inputValue);
          const wait = toast.loading("Please wait...");
          //console.log(inputValue);
          const booked=await booking1(
            inputValue
          );
          toast.update(wait, {
            render: `Welcome ${booked.message}`,
            type: "success",
            isLoading: false,
            autoClose: 10000,
            closeOnClick: true,
            pauseOnHover: true,
          }); 
          navigate('/schedule');
          //window.location.href = '/schedule'
      //dispatch(booking(inputValue))
        }
    }
  const handleOnChange = (value) => {
    if (value === null) {
      value = [];
    } else {
      setSelected(value);
    }
    //console.log(selected);
  };
  const handleOnChange1 = (value1) => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    } else {
      setSelected1(value1);
    }
    //console.log(selected1);
  };
  const labclick = (valu) => {
    //console.log(valu.lab_id);
    if (valu !== "") {
      setShow(true);
      setLabId(valu.lab_title);
      setLabId1(valu.lab_id);
      setInputValue({...inputValue,reference_id:valu.lab_id,type:'lab',register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',parameter:selected,email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:''});
    }
  };
  const fetchData2 = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/lab_cat_list`)
      .then((response) => {
        return response.json();
      })
      .then((data2) => {
        //console.log(data2.data);
        setLabCategory(data2.data);
      });
  };

  if (state === null) {
    //console.log('test');
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/center_lab`)
      .then((response) => {
        setSearchLab(response.data.data);
      });
  }
  
  useEffect(() => {
    // If there is data, the form is valid
    setIsValid(selected1 ? true : false);
    setIsValid1(selected ? true : false);
  }, [selected, selected1]);
  useEffect(() => {
    if (state !== null) {
      //console.log(state.selected);
      //console.log(state.result.data);
      setSelected(state.selected);
      setSelected1(state.selected1);
      setSearchLab(state.result.data);
    }
    fetchData2();
  }, [state]);

  async function labList() {
    //let selected = {selected };
    if (selected1.length === 0) {
      setIsValid(false);
    } else if (selected1.length !== undefined) {
      setIsValid(true);
    } else if (selected.length === 0) {
      setIsValid1(false);
    } else {
      setIsValid1(true);
      let item = { selected };
      var content = { item: selected, city: selected1 };
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/search_lab`, {
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      });
      result = await result.json();
      //console.log(result);
      if (result !== "") {
        navigate("/labs", {
          state: {
            result,
            selected,
            selected1,
          },
        });
      }
    }
  }
  return (
    <div>
      <Header />
      <div className="banner">
        <div className="banner-item banner-bg inner_banner">
          <div>
            <div className="intro">
              <div className="container" style={{ marginTop: `24px` }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="intro-banner-search-form margin-top-95">
                      <div className="intro-search-field with-autocomplete">
                        <div className="input-with-icon">
                          <Select
                            options={
                              typeof areaslistt != "undefined" &&
                              areaslistt.length > 0 &&
                              areaslistt.map((e) => ({
                                label: e.city,
                                value: e.id,
                              }))
                            }
                            optionLabel="selected City"
                            onChange={handleOnChange1}
                            value={selected1}
                            labelledBy="Select City"
                            style={{ backgroundColor: "white", Color: "white" }}
                          />
                          {!isValid && (
                            <p style={{ color: "red" }}>Please Select City</p>
                          )}
                          <i className=""></i>
                        </div>
                      </div>

                      <div className="intro-search-field">
                        <MultiSelect
                          options={labcategory.map((e) => ({
                            label: e.lab_cat_title,
                            value: e.id,
                          }))}
                          optionLabel="selected Lab"
                          value={selected}
                          onChange={handleOnChange}
                          labelledBy="Select Lab"
                        />
                        {!isValid1 && (
                          <p style={{ color: "red" }}>Please Select Lab Test</p>
                        )}
                      </div>
                      <div className="intro-search-button">
                        <button
                          onClick={labList}
                          type="submit"
                          className="button ripple-effect"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <div className="row test-name">
                  <div className="col-md-1" />
                  <div className="col-md-10" align="center">
                    <h3>{labid}</h3>
                    <h4>
                      <i className="fa fa-map-marker" aria-hidden="true" /> (
                      4A/16, Block 4A, Tilak Nagar )
                    </h4>
                  </div>
                  <div className="col-md-1" />
                </div>
                <div className="col-md-1" />
                <div className="row">
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <div className="my-table table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr style={{ background: "#1CBBB4", color: "#fff" }}>
                            <th
                              style={{
                                textAlign: "center",
                                fontFamily: "sans-serif",
                                fontSize: "15px",
                              }}
                            >
                              Test Name
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontFamily: "sans-serif",
                                fontSize: "15px",
                              }}
                            >
                              MRP
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontFamily: "sans-serif",
                                fontSize: "15px",
                              }}
                            >
                              Discount
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontFamily: "sans-serif",
                                fontSize: "15px",
                              }}
                            >
                              Payable
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                fontFamily: "sans-serif",
                                fontSize: "15px",
                              }}
                            >
                              Special Requirements
                            </th>
                          </tr>
                        </thead>
                        {typeof searchlab != "undefined" &&
                          searchlab.length > 0 &&
                          searchlab && (
                            <>
                              {searchlab.map((item2, l) => {
                                const { lab_title, include } = item2;
                                let total = 0;
                                return (
                                  <tbody key={lab_title}>
                                    {typeof include != "undefined" &&
                                      include.length > 0 &&
                                      include && (
                                        <>
                                          {include.map((item3, j = 10) => {
                                            if (lab_title === labid) {
                                              if (item3.sell_price !== 0) {
                                                total += Number(
                                                  item3.sell_price
                                                );
                                              } else {
                                                total += 0;
                                              }
                                              return (
                                                <tr key={j}>
                                                  <td>{item3.lab_cat_title}</td>
                                                  <td>
                                                    <i
                                                      className="fa fa-inr"
                                                      aria-hidden="true"
                                                    />{" "}
                                                    {item3.market_price != null
                                                      ? item3.market_price
                                                      : 0}
                                                  </td>
                                                  <td>
                                                    {item3.discount != null
                                                      ? item3.discount
                                                      : 0}
                                                    .00 %{" "}
                                                  </td>
                                                  <td>
                                                    <i
                                                      className="fa fa-inr"
                                                      aria-hidden="true"
                                                    />
                                                    {item3.sell_price != null
                                                      ? item3.sell_price
                                                      : 0}{" "}
                                                  </td>
                                                  <td>
                                                    No special preparation
                                                    required
                                                  </td>
                                                </tr>
                                              );
                                            } else {
                                              return "";
                                            }
                                          })}
                                        </>
                                      )}
                                    {total !== null &&
                                    total !== undefined &&
                                    total !== 0 ? (
                                      <tr key={l}>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          <i
                                            className="fa fa-inr"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          {total} /-
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr></tr>
                                    )}
                                  </tbody>
                                );
                              })}
                            </>
                          )}
                      </table>
                      <div className="inner-box">
                        <div className="text">
                        <ToastContainer />
                          <div
                            className="doctors-sidebar comment-bottom"
                            style={{ paddingRight: 30, paddingLeft: 30 }}
                          >
                            <form
                              style={{ boxShadow: "0px 0px 0px #7b7b7b" }}
                              onSubmit={handleSubmit}
                            >
                              <div
                                className="form-widget"
                                style={{
                                  border: "1px solid #999",
                                  backgroundImage: `url("http://localhost:3000/assets/img/health-checks-bg.jpg")`,
                                  width: "102%",
                                }}
                              >
                                <div className="form-title">
                                  <h3>Book Appointment</h3>
                                  <p>Monday to Friday: 09:00Am-05:00PM</p>
                                </div>
                                
                                <div className="form-inner">
                                  <div className="appointment-time">
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      value={
                                        token != null ? token["name"] : inputValue.name
                                      }
                                      onChange={handleInput}
                                      required="required"
                                    />
                                    <input
                                      type="text"
                                      name="email"
                                      placeholder="Email"
                                      value={
                                        typeof token != "undefined" &&
                                        token != null
                                          ? token.currentUser.email
                                          : inputValue.email
                                      }
                                      onChange={handleInput}
                                      required="required"
                                    />
                                    {token == null ? (
                                      <div className="form-group">
                                        <input
                                          type="password"
                                          name="password"
                                          placeholder="Password"
                                          value={inputValue.password}
                                          onChange={handleInput}
                                          required="required"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <input
                                      type="text"
                                      name="phone"
                                      placeholder="Phone Number"
                                      onChange={handleInput}
                                      value={
                                        token != null ? token["phone"] : inputValue.phone
                                      }
                                      required="required"
                                    />
                                    <select
                                      className=""
                                      name="gender"
                                      value={inputValue.gender}
                                      onChange={handleInput}
                                      required="required"
                                      style={{ border: "1px solid #999" }}
                                    >
                                      <option data-display="Select Your Gender">
                                        Select Your Gender
                                      </option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                    <br></br>
                                    <input
                                      type="text"
                                      name="age"
                                      value={inputValue.age}
                                      placeholder="Age"
                                      onChange={handleInput}
                                    />
                                    <div className="form-group">
                                      <DatePicker
                                        label="Select Date"
                                        selected={startDate}
                                        onChange={datechange}
                                        dateFormat="yyyy/MM/dd"
                                        required="required"
                                      />
                                      <i className="fa fa-calendar-alt"></i>
                                    </div>
                                    <div className="form-group">
                                      <TimePicker
                                        value={time}
                                        onChange={handleInput1}
                                        start="10:00"
                                        end="21:00"
                                        step={30}
                                      />
                                      <i className="far fa-clock"></i>
                                    </div>
                                  </div>
                                  <div className="choose-service">
                                    <div className="btn-box">
                                      <button
                                        type="submit"
                                        className="theme-btn-one"
                                      >
                                        Book Appoinment
                                        <i className="icon-Arrow-Right" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ width: "105%" }}>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      {/* End Banner */}
      <div className="doctor_list">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="search__result__section__lab__container">
                <div className="search__result__data">
                  <h3>Showing {searchlab.length} results</h3>
                  <p>
                    Below is the list of diagnostic centres with price and
                    discount information. <strong>Click on Book Now</strong> to
                    book online
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="wrapper">
                <button className="form-control toggle-next ellipsis">
                  All Categories (342)
                </button>
                <div className="checkboxes" id="Categories">
                  <label className="apply-selection">
                    <input type="checkbox" defaultValue className="ajax-link" />
                    ✔ apply selection
                  </label>
                  <div className="inner-wrap">
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="all_categories"
                        className="ckkBox all"
                      />
                      <span>All Categories</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="new_car"
                        className="ckkBox val"
                        defaultChecked
                      />
                      <span>Orbit Imaging and Pathlab-Dwarka</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="used_car"
                        className="ckkBox val"
                        defaultChecked
                      />
                      <span>Mahajan Imaging SDA</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="oldtimer"
                        className="ckkBox val"
                      />
                      <span>Mahajan Imaging- Sir Ganga Ram Hospital</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="new_car"
                        className="ckkBox val"
                      />
                      <span>City X-Ray Tilak Nagar</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="used_car"
                        className="ckkBox val"
                      />
                      <span>City X- Ray Vikas Puri</span>
                    </label>
                    <br />
                    <label>
                      <input
                        type="checkbox"
                        defaultValue="oldtimer"
                        className="ckkBox val"
                      />
                      <span>City X Ray Janak Puri</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="list-hr" />
          <div className="row">
            <div className="col-md-9">
              {typeof searchlab != "undefined" &&
                searchlab.length > 0 &&
                searchlab && (
                  <>
                    {searchlab.map((item, l) => {
                      const { lab_title, include, lab_id, lab_lat, lab_long } =
                        item;
                      let dis = getPreciseDistance(
                        { latitude: lt, longitude: lng },
                        {
                          latitude: lab_lat != null ? lab_lat : "",
                          longitude: lab_long != null ? lab_long : "",
                        }
                      );
                      //const background = item.background;
                      var url = "https://www.google.com/maps/dir/?api=1";
                      var origin = "&origin=" + lt + "," + lng;
                      var destination =
                        "&destination=" + lab_lat + "," + lab_long;
                      var newUrl = new URL(url + origin + destination);
                      return (
                        <div key={l}>
                          {typeof include != "undefined" &&
                            include.length > 0 &&
                            include && (
                              <div className="doctor-card  animate__animated animate__delay-1s animate__backInLeft">
                                <div
                                  className="info accordion"
                                  id="accordionExample"
                                >
                                  <div className="avatar">
                                    <Button
                                      variant="primary"
                                      name="lab_name"
                                      value={lab_title}
                                      onClick={() =>
                                        labclick({ lab_title, lab_id })
                                      }
                                      className="btn-book button-4"
                                    >
                                      Book Now
                                    </Button>
                                  </div>
                                  <div className="details">
                                    <div className="row">
                                      <div className="col-md-10">
                                        <div className="name">{lab_title}</div>
                                        <div className="meta-info">
                                          <span className="sp">
                                            <i
                                              className="fa list-icon fa-home"
                                              aria-hidden="true"
                                            />
                                            Home Collection
                                          </span>
                                          <span className="prac-area">
                                            <i
                                              className="fa list-icon fa-certificate"
                                              aria-hidden="true"
                                            />
                                            Certified By NABL
                                          </span>
                                          <span className="exp-yr">
                                            <i
                                              className="fa list-icon fa-clock"
                                              aria-hidden="true"
                                            />
                                            1227 times booked this week
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <button
                                          data-toggle="collapse"
                                          data-target={"#" + lab_title}
                                          className="blue-btn accordion-toggle"
                                        >
                                          Price Details
                                          <i className="fa fa-angle-down" />
                                        </button>

                                        <p>{`Distance\n\n${dis} Meter\nOR\n${
                                          dis / 1000
                                        } KM`}</p>
                                        <button
                                          onClick={() =>
                                            openLinkInNewTab(newUrl)
                                          }
                                        >
                                          {" "}
                                          Direction{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id={lab_title}
                                    className="accordian-body collapse"
                                  >
                                    <div className="search__price__breakup__2">
                                      <p className="mb-0">Test Name</p>
                                      <p className="mb-0">MRP</p>
                                      <p className="mb-0">Discount</p>
                                      <p className="mb-0">Payable</p>
                                    </div>
                                    <div className="search__price__breakup__3__container">
                                      {typeof include != "undefined" &&
                                        include.length > 0 &&
                                        include && (
                                          <div>
                                            {include.map((item1, j = 2) => {
                                              return (
                                                <div
                                                  key={j}
                                                  className="search__price__breakup__3"
                                                  style={{
                                                    borderbottom:
                                                      "1px solid #c1c1c1",
                                                  }}
                                                >
                                                  <p className="mb-0">
                                                    {item1.lab_cat_title}
                                                  </p>
                                                  <p className="mb-0">
                                                    {item1.market_price}
                                                  </p>
                                                  <p className="mb-0">
                                                    {item1.discount}%
                                                  </p>
                                                  <p className="mb-0">
                                                    {item1.sell_price}
                                                  </p>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </>
                )}
            </div>
            <div className="col-md-3">
              <nav>
                <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active btn-tab"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Latest News
                  </button>
                  <button
                    className="nav-link btn-tab"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Doctor Profile
                  </button>
                </div>
              </nav>
              <div
                className="tab-content p-3 border bg-light"
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="parent">
                    {/*       child 1 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                    {/*       child 2 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                    {/*       child 3 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div className="parent">
                    {/*       child 1 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                    {/*       child 2 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                    {/*       child 3 */}
                    <div className="child">
                      <div className="left">
                        <div className="icon" />
                        <div className="info">
                          <h5 className="name">UI Design Daily</h5>
                          <p className="cat">Design</p>
                        </div>
                      </div>
                      <div className="right">4</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
