import useFormContext from "../hooks/useFormContext";
import React from 'react';
import { useDispatch } from 'react-redux';
import{signUp} from '../features/counter/apiSlice';
import { booking1 } from "../helper/formdata";
import { toast } from 'react-toastify';
import Form from './Form';
const Shipping = () => {
    const { data, handleChange} = useFormContext();
    const dispatch=useDispatch();
    const token = JSON.parse(localStorage.getItem("user-info-login"));
    const handleSubmit = async (e) => {
      //console.log(data);
      e.preventDefault();
      try {
       
        if(token === null)
        {
          dispatch(signUp(data));
        }
        //console.log(params.id);
        const wait = toast.loading("Please wait...");
        //console.log(inputValue);
        const booked=await booking1(
          data
        );
        toast.update(wait, {
          render: `Welcome ${booked.message}`,
          type: "success",
          isLoading: false,
          autoClose: 10000,
          closeOnClick: true,
          pauseOnHover: true,
        }); 
        //window.location.href = '/schedule'
    //dispatch(booking(inputValue))      
      } catch (err) {
        //console.log(err);
      }
      //console.log(JSON.stringify(data))
  }
 
    const content = (
        <>
        <section className="personal_info">
        <div className="login-box1">
        <form onSubmit={handleSubmit}>
           <fieldset className="checkbox-group">
  <legend className="checkbox-group-legend">Select members you want to insure</legend>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input name="plan_checkbox" type="checkbox" value="Self" className="checkbox-input" onChange={handleChange} />
      <span className="checkbox-tile">
        <span className="checkbox-icon">
          <img src="assets/img/Self-husband.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Self</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input name="plan_checkbox" type="checkbox" className="checkbox-input"  value='Wife'
                        onChange={handleChange} />
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Wife.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Wife</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input name="plan_checkbox" type="checkbox" className="checkbox-input" value="Son"
                        onChange={handleChange} />
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Son.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Son</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input name="plan_checkbox" type="checkbox" value="Daughter" className="checkbox-input"  onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Daughter.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Daughter</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" value="Mother" className="checkbox-input" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Mother.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Mother</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" value="Father" className="checkbox-input" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Father.webp" alt=""></img> 
        </span>
        <span className="checkbox-label">Father</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" value="Father in law" className="checkbox-input" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Father-in-law.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Father in law</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" value="Mother in Law" className="checkbox-input" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Mother-in-Law.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Mother in Law</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" className="checkbox-input" value="Grand Father" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Grand-father.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Grand Father</span>
      </span>
    </label>
  </div>
  <div className="checkbox">
    <label className="checkbox-wrapper">
      <input type="checkbox" className="checkbox-input" value="Grand Mother" onChange={handleChange}/>
      <span className="checkbox-tile">
        <span className="checkbox-icon">
        <img src="assets/img/Grand-mother.webp" alt=""></img>
        </span>
        <span className="checkbox-label">Grand Mother</span>
      </span>
    </label>
  </div>
</fieldset>

<Form/>
</form>
</div>
</section>
        </>
    )

    return content
}
export default Shipping