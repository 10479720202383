import React,{useEffect, useState,useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-bootstrap-time-picker';
import { Header } from './Header';
import { Footer } from './Footer';
import {ReviewForm} from "./ReviewForm";
import { Link } from "react-router-dom";
import {useParams} from "react-router-dom";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { ToastContainer, toast } from 'react-toastify';
import{register,signUp} from '../../features/counter/apiSlice';
import { booking,booking1 } from "../../helper/formdata";
import { useDispatch, useSelector } from "react-redux";
import Parser from 'html-react-parser';
import { Tooltip } from "bootstrap";
import axios from "axios";
//console.log(Events);
export const DoctorDetails = (props) => {
  //console.log(props);
  const token = JSON.parse(localStorage.getItem('user-info-login')); 
  let firstDaty = 1; 
  let params = useParams();
const [startDate, setStartDate] = useState(new Date());
const [booking_status, setBooking_status] = useState("");
const [age, setAge] = useState("");
const [gender, setGender] = useState("");
const [time, setTime] = useState("");
const [appoinment, setAppoinment] = useState("");
const [count1, setCount1] = useState(0);
const [activeTab, setActiveTab] = useState("tab1"); 
const [message, setMessage] = useState("");
const [overviews, setOverviews] = useState(""); 
const dispatch=useDispatch();
const [inputValue,setInputValue]=useState({name:'',email:(typeof token != 'undefined' && token != null) ? token.currentUser.email:'',phone:'',password:'',startDate:startDate,subject:'',comments:'',age:'',gender:'',time:'',reference_id:params.id,register_id:(typeof token != 'undefined' && token != null ) ? token.currentUser.uid:'',booking_status:'booked',type:'doctor'})

//console.log(token['email']);
const formRef=useRef();
let tooltipInstance = null;

  const [doctordetail, setDoctorDetail] = useState([]);
  useEffect(() => {  
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/doctor_detail/${params.id}`)
        .then((response) => { setDoctorDetail(response.data.data[0]);
          setOverviews(response.data.data[0].doctor_overview);
        });
        
  }, [params.id])
  let array = [];
  let array1 = appoinment;
  
  array.push(appoinment); 
  
  const Events = [
    array
  ];
 // console.log(appoinment);
  //console.log(Events);
    //const [isActive, setIsActive] = useState(false);
    const handleTab1 = () => {
        // update the state to tab1
        setActiveTab("tab1");
      };
     
      const handleTab2 = () => {
        // update the state to tab2
        setActiveTab("tab2");
      };
      const handleTab3 = () => {
        // update the state to tab2
        setActiveTab("tab3");
      };
      const handleTab4 = () => {
        // update the state to tab2
        setActiveTab("tab4");
      }; 
      const handleInput=(e)=>
        {
          
          setInputValue({...inputValue,[e.target.name]:e.target.value});
          //console.log(inputValue);
        }
      const handleSubmit=async(e)=>
        {
          //console.log('test');
          e.preventDefault();
          if(token === null)
            {
              dispatch(signUp(inputValue));
              
            }
            else
            {
              const wait = toast.loading("Please wait...");
              console.log(inputValue);
              const booked=await booking1(
                inputValue
              );
              toast.update(wait, {
                render: `Welcome ${booked.message}`,
                type: "success",
                isLoading: false,
                autoClose: 10000,
                closeOnClick: true,
                pauseOnHover: true,
              }); 
              window.location.href = '/schedule'
          //dispatch(booking(inputValue))
            }
        }
      const handleMouseEnter = (info) => {
        //console.log(info.event.extendedProps.description);
        if (info.event.extendedProps.description) {
          tooltipInstance = new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            html: true,
            placement: "top",
            trigger: "hover",
            container: "body"
          });
    
          tooltipInstance.show();
        }
      };
    
      const handleMouseLeave = (info) => {
        if (tooltipInstance) {
          tooltipInstance.dispose();
          tooltipInstance = null;
        }
      };
  return (    
    <div>       
	<Header />
 
    <div className="clearfix"></div>
    <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
        
    <section className="doctor-details bg-color-3">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12 col-sm-12 content-side">
              <div className="clinic-details-content doctor-details-content">
                <div className="clinic-block-one">
                  <div className="inner-box">
                    <figure className="image-box"><img src={(doctordetail.doctor_image ? doctordetail.doctor_image:'') } alt="" /></figure>
                    <div className="content-box">
                      <div className="like-box"><Link to=""><i className="fa fa-heart" /></Link></div>
                      <div className="share-box">
                        <Link to="" className="share-btn"><i className="fa fa-share-alt" /></Link>
                      </div>
                      <ul className="name-box clearfix">
                        <li className="name"><h2>{doctordetail.first_name} {doctordetail.last_name}</h2></li>
                        <li><i className="icon-Trust-1" /></li>
                        <li><i className="icon-Trust-2" /></li>
                      </ul>
                      <strong style={{marginLeft:150}}className="designation">{doctordetail.doctor_qualification}</strong>
                        <div className="rating-box clearfix">
                        <ul className="rating clearfix">
                          <li><i className="icon-Star" /></li>
                          <li><i className="icon-Star" /></li>
                          <li><i className="icon-Star" /></li>
                          <li><i className="icon-Star" /></li>
                          <li><i className="icon-Star" /></li>
                          <li><Link to="">(32)</Link></li>
                        </ul>
                      </div>
                      <div className="text">
                        {(overviews ? Parser(overviews.substring(0, 150)) : ''   )}
                      </div>
                      <div className="lower-box clearfix">
                        <ul className="info clearfix">
                          <li><i className="fa fa-map-marker-alt" />{doctordetail.address}, {doctordetail.city}</li>
                          <li><i className="fa fa-phone" /><Link to="tel:2265458856">+(22) 65_458_856</Link></li>
                        </ul>
                        <div className="view-map"><Link to="">View Map</Link></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-box">
                  <div className="tab-btn-box centred">
                    <ul className="tab-btns tab-buttons clearfix">
                      <li className={activeTab === "tab1" ? "tab-btn active-btn" : ""} data-tab="#tab-1" onClick={handleTab1}>Overview</li>
                      <li className={activeTab === "tab2" ? "tab-btn active-btn" : ""} data-tab="#tab-2" onClick={handleTab2}>Experience</li>
                      <li className={activeTab === "tab3" ? "tab-btn active-btn" : ""} data-tab="#tab-3" onClick={handleTab3}>Location</li>
                      <li className={activeTab === "tab4" ? "tab-btn active-btn" : ""} data-tab="#tab-4" onClick={handleTab4}>Reviews</li>
                    </ul>
                  </div>
                  <div className="tabs-content">
                  {activeTab === "tab1" ?  <div className={activeTab === "tab1" ? "tab active-tab" : ""} id="tab1">
                      <div className="inner-box">
                        <div className="text">   
                               
                        {Parser(overviews)} 
                        </div>                       
                        
                      </div>
                    </div> : (activeTab === "tab2" ? <div className={activeTab === "tab2" ? "tab active-tab" : ""} id="tab2">
                      <div className="experience-box">
                        <div className="text">
                        {(Parser(doctordetail.doctor_experience))}
                        </div>
                      </div>
                    </div>                   
                   : ( activeTab === "tab3"?
                    <div className={activeTab === "tab3" ? "tab active-tab" : ""} id="tab-3">
                      <div className="location-box">
                      {(Parser(doctordetail.doctor_location))}
                      </div>
                    </div>
                    : 
                    <div className={activeTab === "tab4" ? "tab active-tab" : ""} id="tab-4">
                      <ReviewForm profileDetails={props}/>
                    </div>
                    )
                    )
                  }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div className="doctors-sidebar">                          
              {token && token['id'] && appoinment.length > 0 && count1 > 1 ? (              
             <FullCalendar
             plugins={[ dayGridPlugin ]}
             eventMouseEnter={handleMouseEnter}
      eventMouseLeave={handleMouseLeave}
             initialView="dayGridMonth"            
             events={appoinment}
           />
              
                ) : (
                  <form ref={formRef} onSubmit={handleSubmit}>
                <div className="message contact-address-headline success">{props.message !='' ? <p>{props.message}</p> : props.validation}</div>
                <div className="form-widget">
                  <div className="form-title">
                    <h3>Book Appointment</h3>
                    <p>Monday to Friday: 09:00Am-05:00PM</p>
                  </div>
                  <div className="form-inner">
                  <div className="appointment-time">                    
                  <div className="form-group">
                 <input type="text" name="name" placeholder="Name" defaultValue={(token != null) ? token['name'] : ''}  onChange={handleInput} required="required"/>
     
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="email" placeholder="Email" defaultValue={(token != null && token != undefined) ? ((typeof token.currentUser != 'undefined') ?token.currentUser.email:token['email']) : inputValue.email} onChange={handleInput} required="required"/>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="phone" placeholder="Phone Number"  defaultValue={(token != null) ? token.currentUser.phoneNumber : inputValue.phone} onChange={handleInput} required="required"/>
                                        </div>
                                        {(token == null) ?
                                        <div className="form-group">
                                            <input type="password" name="password" placeholder="Password"   onChange={handleInput} required="required"/>
                                        </div>
                                        : ''}
                                        <div className="form-group select-box">
                                            <select className="wide" name="gender"  value={inputValue.gender} onChange={handleInput} required="required">
                                               <option data-display="Select Your Gender">Select Your Gender</option>
                                               <option>Male</option>
                                               <option>Female</option>                                               
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="age"   value={inputValue.age} placeholder="Age" required="required" onChange={handleInput} />
                                        </div>                                        
                            <div className="form-group">
                            <DatePicker selected={startDate} minDate={startDate} value={startDate} onChange={(date) => setStartDate(date)} required="required"/>
                                <i className="fa fa-calendar-alt"></i>
                            </div>
                            <div className="form-group">
                            <TimePicker selected={time} start="10:00" end="21:00" value={time} step={30} onChange={handleInput} required="required"/>
                                <i className="far fa-clock"></i>
                            </div>
                        </div>
                    <div className="choose-service">                  
                      
                      <div className="btn-box">
                        <button  className="theme-btn-one">Book Appoinment<i className="icon-Arrow-Right" /></button>
                      </div>
                    </div>
                  </div>
                </div>
                </form>
                 
                            )}
              </div>
            </div>
          </div>
        </div>
      </section>
	   <Footer />
    </div>
  );
};