import React,{ Fragment,useEffect, useState } from 'react';
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
export const DoctorItem = (doctor_item) => {  
        //console.log(doctor_item.item.doctor_price);  
  return (
    <>
   
					
				   <div key={doctor_item.item.id}>
						<div className="new_dr_card" key={doctor_item.item.id}>
							<div className="social_m">
								<ul className="social_icons">
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-facebook-f"></i>
										</Link>
									</li>
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-twitter"></i>
										</Link>
									</li>
									<li>
										<Link to="#" target="blank" className="social_icon"> <i className="fa fa-instagram"></i>
										</Link>
									</li>
								</ul>
							</div>
							<div className="dr_card_img">
								<Link to="#"><img title="" alt="" src={(typeof doctor_item.item.doctor_image !=undefined ? doctor_item.item.doctor_image:'')} /></Link>
							</div>
							<div className="dr_card_content">
								<strong className="dr_name">{doctor_item.item.first_name} {doctor_item.item.last_name}</strong>
								<h2 className="dr_name">{doctor_item.item.doctor_cat_title}</h2>
								<span className="dr_year">{doctor_item.item.doctor_experience}</span>
								{doctor_item.item.doctor_price !=='' && doctor_item.item.doctor_price !==null &&
								<span className="dr_pricing">
									<span className="value"><i className="fa fa-inr" aria-hidden="true"></i>  {doctor_item.item.doctor_price}</span>
									
								</span>
								}
								<hr/>
								<p> {doctor_item.item.doctor_experience}</p>
								<p> {doctor_item.item.doctor_qualification}</p>
								<p><Link to={`/specialist/${doctor_item.item.id}`} className="know-more-btn">View More</Link></p>
							</div>
						</div>
						</div>
						
						</>
  );
						
};
