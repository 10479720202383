import React,{ Fragment,useEffect, useState } from 'react';
import { Link,useLocation } from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import { DoctorItem } from './DoctorItem';
import Paginate from './Paginate';
import axios from "axios";
import Select from 'react-select';
import store from '../../app/store';
import { useDispatch,useSelector } from 'react-redux';
import{arealist} from '../../features/counter/apiSlice';
import { MultiSelect } from "react-multi-select-component";
import { fetchDataWithRetry } from "../../helper/formdata"; 
export const Doctor = () => {
	//console.log(props);
	const [selected1, setSelected1] = useState([]);
	const [isValid, setIsValid] = useState(true);
	const [selected, setSelected] = useState([]);
	const [specialist, setSpecialist] = useState([]);
	const location = useLocation();
	const { ids } = location.state || { ids: "none" };
	const { type } = location.state || { type: "none" };
	const [category, setCategory] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage] = useState(20);
   const [error, setError] = useState(false);
   const indexOfLastPost = currentPage * postsPerPage;
   const indexOfFirstPost = indexOfLastPost - postsPerPage;
   const currentPosts = specialist.slice(indexOfFirstPost, indexOfLastPost);
	//console.log(ids);
	const areaslistt=useSelector(arealist);
  //console.log(areaslistt);
  const dispatch=useDispatch();
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
}  
	const handleOnChange1 = value1 => {
		//console.log(value1);
		if (value1 === null) {
		  value1 = [];
		}
		else
		{
			setSelected1(value1);
		}
		//console.log(selected1);
	  };
	const handleOnChange = value => {   
		if (value === null) {
		  value = [];
		}
		else
		{
			
			setSelected(value);
		}
		//console.log(selected);
	  };
	  async function doctorList(ids) {		
		let type = 'hospital';	
		const reactData = {"item":selected,"city":selected1,"type":type,"ids":ids};	
        //console.log(ids);
	     if(selected.length !==0)
         {
			let result = await fetch(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list1`,
				{
					method: 'POST',
					body: JSON.stringify(reactData),
					headers:
					{
						"Content-Type": 'application/x-www-form-urlencoded',
						"Accept": 'application/json'
					}
				})
			result = await result.json();
			//console.log(JSON.stringify(item,type));
			setSpecialist(result.data);
            }
	  }	
	

	 
		useEffect(() => {
			//console.log(type);
			if ((ids !== 'none') && (selected.length === 0) && (ids !== 'hospital'))
			{
				fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((cat) => {
					setSpecialist(cat.data);
				
				  }).catch((error) => {
				 setError(error);
				 });	
			// axios
			// 	.get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list/${ids}`)
			// 	.then((response) => { setSpecialist(response.data.data); });
			}
			if ((type === 'hospital') && (selected.length === 0))
			{
				//console.log(selected.length);
				fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((cat) => {
					setCategory(cat.data);
				
				  }).catch((error) => {
				 setError(error);
				 });
			// 	axios
			// .get(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`)
			// .then((response) => { setCategory(response.data.data); });
			}
			fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/hospitallist/${ids}`).then((hos) => {
				setSpecialist(hos.data);
			
			  }).catch((error) => {
			 setError(error);
			 });
			// axios
			// 	.get(`${process.env.REACT_APP_BASE_URL}/hospitallist/${ids}`)
			// 	.then((response) => { setSpecialist(response.data.data); });
		  }, [ids,selected.length])
	
          
  return (
    <div>
	<Header />
	<div className="banner">
<div className="banner-item banner-bg inner_banner"> 

<div>     
    <div className="intro">
	    <div className="container" style={{ marginTop: `24px` }}>     
      
      <div className="row">
        <div className="col-md-12">
          <div className="intro-banner-search-form margin-top-95">   
            
            <div className="intro-search-field with-autocomplete">
              <div className="input-with-icon">
			  <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"       
        labelledBy="Select City" 
		onChange={handleOnChange1}
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
	  {!isValid && <p style={{color:'red'}}>Please Select City</p>}
                <i className="fas fa-tint"></i> </div>
            </div>          
            
            <div className="intro-search-field">
            <MultiSelect
  options={category.map(e => ({ label: e.doctor_cat_title, value: e.id}))}
  optionLabel="selected Lab"
  value={selected} 
  onChange={handleOnChange}      
   
  labelledBy="Select Lab"
 
/>
            </div>
            
            <div className="intro-search-button">
			<button onClick={() => doctorList({ids})} type="submit" className="button ripple-effect">Search</button>
            </div>
          </div>
        </div>
      </div>
      
      </div>
            </div>
    </div>
            
        </div>
        </div>

    <div className="doctor_list">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 doctor_d">
					<figure style={{ 
      width:'317px'
    }}><img src="assets/img/doctor1.jpg" alt="doctor1"/></figure>
					<div className="inner_column">
						<h3 className="name">Dr. Addision Smith</h3>
						<span className="year">7+ Year</span>
						<span className="designation">Senior Dr. at Delmont (Pediatrics)</span>	
						<span className="pricing1">
							<span className="value"> - $150</span>
							<span className="off">$250</span>
						</span>
						<div>
						<h4>Lorem Ipsum</h4>
						<p>Professor Joshua Clark studied at Havard and qualified in medicine at Harvard Medical School in 1987. He then worked at various New York hospitals for the next five years, including Seattle, and Washington. He also held positions with the Medical Research Council, NY Heart Foundation and the Wellcome Trust.</p>

						<p>He worked as a thoracic and general surgeon in the north of NY and was appointed as a senior researcher in the Medical Research Institute of the New York Academy of Medical Sciences, with the main clinical focus of oesophageal surgery. He continued in this post until his move to the Seattle in the early 1992.</p>

						<p>He worked as a thoracic and general surgeon in the north of NY and was appointed as a senior researcher in the Medical Research Institute of the New York Academy of Medical Sciences, with the main clinical focus of oesophageal surgery. He continued in this post until his move to the Seattle in the early 1992.</p>
						</div>
						<div className="contact_f">
							<h5>Contact Us</h5>
							<form method="" action="">
								<div className="form-group">
									<input type="text" name="username" placeholder="Name *" required="" />
								</div>	
								<div className="form-group">
									<input type="text" name="phone" placeholder="Phone *" required="" />
								</div>
								<div className="form-group">
									<input type="email" name="email" placeholder="Email *" required="" />
								</div>								
								<div className="form-group">
									<button className="send_btn" type="submit" name="submit-form"><span className="btn-title">Send</span></button>
								</div>
							</form>
						</div>
						
					</div>
				</div>
                <div className="col-sm-6 col-md-6 col-lg-6">
					<div className="doctor_l">
					{
          currentPosts.map(itttem => {
            return  <DoctorItem key={itttem.id} item={itttem}  />
			
          })}   
			
		
					</div>
				</div>
				<Paginate
                  postsPerPage={postsPerPage}
                  totalPosts={specialist.length}
                  paginate={paginate}
               />
			</div>
		</div>
	</div>

	   <Footer />
    </div>
  );
};
