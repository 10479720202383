import { FormProvider } from "../context/FormContext"
import { Header } from '../components/Pages/Header';
import { Footer } from '../components/Pages/Footer';
import { Link } from "react-router-dom";
import FormInputs from './FormInputs';
import { ToastContainer, toast } from 'react-toastify';
export const Insurance = () => {
  return (
    
    <FormProvider>
      <Header />
      <ToastContainer />
      <div className="clearfix"></div>
     <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/>
  <div className="section">
    <div className="container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="#">Home</Link></li>
          <li className="breadcrumb-item active" aria-current="page">Insurance</li>
        </ol>
      </nav>
    </div>
  </div>  
  <FormInputs />
      <br></br>
      <Footer />
    </FormProvider>
  )

}
