import React,{ Fragment,useEffect, useState } from 'react';
import { useParams} from "react-router-dom";
import { Header } from './Header';
import { Footer } from './Footer';
import axios from "axios";
import Parser from 'html-react-parser';
import {ReviewForm} from "./ReviewForm";
export const BlogDetails = (props) => {
  let params = useParams();
 // const location = useLocation();
  //const { id } = location.state || { id: "none" };
  const [articledetail, setArticleDetail] = useState([]);
  
  useEffect(() => {
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/articledetail/${params.id}`)
        .then((response) => { setArticleDetail(response.data.data); });
  }, [params.id])
  
  //console.log(articledetail);
  //console.log(params.id);
 
    return (
        <div>
        <Header />
        <div className="clearfix"></div>
    <div className="banner">
        <div className="banner-item banner-bg inner_banner">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
<section className="sidebar-page-container">
  <div className="auto-container">
    <div className="row clearfix">
      <div className="col-lg-8 col-md-12 col-sm-12 content-side">
        <div className="blog-details-content">
        {typeof articledetail != 'undefined' && articledetail.length > 0 && articledetail && (
						<>
						{articledetail.map((item, i) => {
				   const { art_id, art_title,art_banner,art_desc} = item;
				   return (
                           <div key={art_id}>
          <div className="news-block-one">
            <div className="inner-box">
              <figure className="image-box">
                <img src={art_banner} alt="" />
                <span className="category">Featured</span>
              </figure>
              <div className="lower-content">
                <h3>{art_title}</h3>
                <ul className="post-info">
                  <li><img src="assets/img/sandeep-modified.png" alt="" /><a href="index-2.html">Sandeep Sahu</a></li>
                  <li>April 23, 2020</li>
                </ul>
                {Parser(art_desc)}
              </div>
            </div>
          </div>
          </div>
                            );
                        })}
                                      </>
                                      )}
          <blockquote>
            <i className="fas fa-quote-left" />
            <p>“Enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat duis aute irure dolor in reprehen</p>
          </blockquote>
          <div className="two-column">
            <div className="row clearfix">
              <div className="col-lg-6 col-md-6 col-sm-12 image-column">
                <figure className="image-box"><img src="assets/img/news/news-19.jpg" alt="" /></figure>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 text-column">
                <div className="text">
                  <h3>Two Most-Cited Reason</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <p>enim ad minim veniam quis nostrud exercitat ullamco laboris nisi ut aliquip ex ea commodo consequat</p>
                  <ul className="list clearfix">
                    <li>Success is something of which we want.</li>
                    <li>Most people believe that success is difficult.</li>
                    <li>the four levels of the healthcare system</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="text">
            <h3>Two Most-Cited Reason</h3>
            <p>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat duis aute irure dolor.</p>
          </div>
          <div className="post-share-option clearfix">
            <div className="text pull-left"><h4>We Are Social On:</h4></div>
            <ul className="social-links clearfix pull-right">
              <li><a href="blog-details.html"><i className="fab fa-facebook-f" /></a></li>
              <li><a href="blog-details.html"><i className="fab fa-twitter" /></a></li>
              <li><a href="blog-details.html"><i className="fab fa-google-plus-g" /></a></li>
            </ul>
          </div>
          <div className="comment-box">
            <div className="group-title">
              <h3>Comments</h3>
            </div>
            <div className="comment">
              <figure className="thumb-box">
                <img src="assets/img/news/comment-1.png" alt="" />
              </figure>
              <div className="comment-inner">
                <div className="comment-info">
                  <h5>Leroy Anderson</h5>
                  <span className="comment-time">April 10, 2020</span>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc itation ullamco laboris.</p>
                <a href="blog-details.html" className="reply-btn">Reply</a>
              </div>
            </div>
            <div className="comment">
              <figure className="thumb-box">
                <img src="assets/img/news/comment-2.png" alt="" />
              </figure>
              <div className="comment-inner">
                <div className="comment-info">
                  <h5>Leroy Anderson</h5>
                  <span className="comment-time">April 09, 2020</span>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc itation ullamco laboris.</p>
                <a href="blog-details.html" className="reply-btn">Reply</a>
              </div>
            </div>
            <div className="comment">
              <figure className="thumb-box">
                <img src="assets/img/news/comment-3.png" alt="" />
              </figure>
              <div className="comment-inner">
                <div className="comment-info">
                  <h5>Leroy Anderson</h5>
                  <span className="comment-time">April 08, 2020</span>
                </div>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit sed do eiusmod tempor incidid unt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc itation ullamco laboris.</p>
                <a href="blog-details.html" className="reply-btn">Reply</a>
              </div>
            </div>
          </div>
          <div className="comments-form-area">
            <div className="group-title">
              <h3>Leave a Comment</h3>
            </div>
            <ReviewForm profileDetails={props}/>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</section>

<Footer />
</div>
  );
};