import React,{ Fragment,useEffect, useState,useMemo } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { Link,useNavigate } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import { useSelector } from 'react-redux';
import{arealist,currentcity} from '../../features/counter/apiSlice';
import { MultiSelect } from "react-multi-select-component";
import { fetchDataWithRetry } from "../../helper/formdata";
import Select from 'react-select';
const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
    responsive1:{
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
}

	
function Home(props) {   
    //console.log(props);
const navigate = useNavigate();
const [slider, setSlider] = useState([]);
const city=useSelector(currentcity);
//console.log(city);
  const [selected, setSelected] = useState([]);
  const [selected1, setSelected1] = useState(city);
  const [doctorcount, setDoctorCount] = useState(0);
  const [labcount, setLabCount] = useState(0);
  const [hospitalcount, setHospitalCount] = useState(0);
  const [healthcount, setHealthCount] = useState(0);
  const [cliniccount, setClinicCount] = useState(0);
  //const [areas, setAreas] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [isValid1, setIsValid1] = useState(true);
  const [labcategory, setLabCategory] = useState([]);
 const [health, setHealth] = useState([]);
 const [category, setCategory] = useState([]);
 const [worker, setWorker] = useState(null);
  const [error, setError] = useState(false);
  //console.log(selected1);
  const areaslistt=useSelector(arealist);
  
//   const fetchData = async() => {
  
//     await fetch(`${process.env.REACT_APP_BASE_URL}/banner_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data32 => {
//         //console.log(data32.data);
//         setSlider(data32.data);
		
//       })
  
//   }
  useEffect(() => {
    setSelected1(city);
  }, [city]);
  useEffect(()=>{
    
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/doctor`).then((doctor) => {
        
                setDoctorCount(doctor.data);
            
    }).catch((error) => {
        setError(error);
      });
   
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/lab`).then((lab) => {
        
                setLabCount(lab.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/hospital`).then((hospital) => {
        
                setHospitalCount(hospital.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/clinic`).then((clinic) => {
        
                setClinicCount(clinic.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/home_page_details/health`).then((health) => {
        
                setHealthCount(health.data);
            
    }).catch((error) => {
        setError(error);
      });
    // let urlOne=`${process.env.REACT_APP_BASE_URL}/home_page_details/doctor`;
    // let urlTwo=`${process.env.REACT_APP_BASE_URL}/home_page_details/lab`;
    // let urlThree=`${process.env.REACT_APP_BASE_URL}/home_page_details/hospital`;
    // let urlFour=`${process.env.REACT_APP_BASE_URL}/home_page_details/clinic`;
    // let urlFive=`${process.env.REACT_APP_BASE_URL}/home_page_details/health`;
    // Promise.all([
    //     fetch(urlOne).then(res => res.json()),
    //     fetch(urlTwo).then(res => res.json()),
    //     fetch(urlThree).then(res => res.json()),
    //     fetch(urlFour).then(res => res.json()),
    //     fetch(urlFive).then(res => res.json())
    // ]).then(([urlOneData, urlTwoData,urlThreeData,urlFourData,urlFiveData]) => {
    //    setDoctorCount(urlOneData);
    //    setLabCount(urlTwoData);
    //    setHospitalCount(urlThreeData);  
    //    setClinicCount(urlFourData);
    //    setHealthCount(urlFiveData);
    // }) 
    
},[]) 


//   const fetchData3 = async() => {
//     await fetch(`${process.env.REACT_APP_BASE_URL}/health_list`)
//       .then(response => {
//         return response.json()
//       })
//       .then(data3 => {
// 		  //console.log(data2.data);
//         setHealth(data3.data)
		
//       })
//   }
  
  useMemo(() => {
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/banner_list`).then((banner) => {
        setSlider(banner.data);
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/health_list`).then((health) => {
        
        setHealth(health.data);
            
    }).catch((error) => {
        setError(error);
      });
    fetchDataWithRetry(`${process.env.REACT_APP_BASE_URL}/doctor_cat_list`).then((doctor_cat) => {
        setCategory(doctor_cat.data);
            
    }).catch((error) => {
        setError(error);
      });
    //fetchData();
	//fetchData1();
	//fetchData2();
	//fetchData3();
    //fetchArea();
  }, []);  
  //const [doctorlist, setDoctorlist] = useState([]);
 
  const handleOnChange = value => {
    //console.log(value);
    if (value === null) {
      value = [];
    }
    else
    {
        setSelected(value);
    }
    //console.log(selected);
  };
  const handleOnChange1 = value1 => {
    //console.log(value1);
    if (value1 === null) {
      value1 = [];
    }
    else
    {
        setSelected1(value1);
    }
    //console.log(selected1);
  };
  useEffect(() => {
    // If there is data, the form is valid
    setIsValid(selected1 ? true : false);
    setIsValid1(selected ? true : false);
  }, [selected,selected1]);
  async function labList() {
    //let selected = {selected };
    //console.log(selected.length);
    if(selected1.length === 0)
    {
        setIsValid(false);
    }
    else if(selected1.length !==undefined)
    {
        setIsValid(true);
    }
    else if(selected.length === 0)
    {
        setIsValid1(false);
    }
    else
    {
        setIsValid1(true);    
    let item = {selected};
    var content={"item":selected,"city":selected1};
    //console.log(content);
		let result = await fetch(`${process.env.REACT_APP_BASE_URL}//search_lab`,
			{
				method: 'POST',
				body: JSON.stringify(content),
				headers:
				{
					"Content-Type": 'application/x-www-form-urlencoded',
					"Accept": 'application/json'
				}
			})
		result = await result.json();
        console.log(result);
		//setMsg(result.message);
    if (result !=='') {	
        navigate(
            'labs',
            {
              state: {
                result,
                selected,
                selected1
              }
            }
          );
       
    }
}
  }

  useEffect(() => {
    // Initialize the worker
    const newWorker = new Worker(new URL('./worker.js', import.meta.url));
    setWorker(newWorker);

    newWorker.onmessage = function(e) {
      const { status, results, error } = e.data;
      if (status === 'success') {
        setLabCategory(results[0].data);
        //setCategory(results[1].data);
        //console.log(results[0].data);
      } else if (status === 'error') {
        setError(error);
      }
    };

    // Fetch data when the component mounts
    newWorker.postMessage({ urls: [`${process.env.REACT_APP_BASE_URL}/lab_cat_list`] });

    // Cleanup worker on component unmount
    return () => {
      newWorker.terminate();
    };
  }, []);
return (   

<Fragment>

<Header />

        <div className="intro-banner" style={{ 
      backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/img/home-background1.jpg)`,height:650
    }}>
         
          {typeof slider != 'undefined' && slider.length > 0 && slider && (
			
            <OwlCarousel  className="owl-carousel main_imgslider owl-theme owl-loaded owl-drag" 
             items={1}  autoplay ={true}            
             loop margin={10}
            autoplaySpeed={true}
            autoWidth={true}
            touchDrag={true}
            
             responsive={state.responsive1}// add this line
            animateOut={'fadeOut'}
            animateIn={'flipInX'}>
              {slider.map((item,index) => {
              const { slider_image } = item;
              return (
              <div key={index}>
            <div className="item">
              <img src={slider_image} loading="lazy" alt="" className="img-responsive" /> 
            </div>
            </div>
					
                    );
                })}
                          </OwlCarousel> 
                      )}
        <br/>
          <div className="container"> 
            {/* Intro Headline */}
            <div className="row">
              <div className="col-md-12">
                <div className="banner-headline">
                  <h3> <strong>Find Best Labs At Affordable Prices</strong> <br />
                    <span>Assured Quality, Best Prices, Cost Comparison &amp; Huge Discounts</span> </h3>
                </div>
              </div>
            </div>
            {/* Search Bar */}
            <div className="row">
              <div className="col-md-12">
                <div className="intro-banner-search-form margin-top-95"> 
                  {/* Search Field */}
                  <div className="intro-search-field with-autocomplete">
                    <div className="input-with-icon">
                    <Select
        options={typeof areaslistt != 'undefined' && areaslistt.length > 0 && areaslistt.map(e => ({ label: e.city, value: e.id}))}
        optionLabel="selected City"
        onChange={handleOnChange1}
        value={selected1} 
        labelledBy="Select City" 
        style={{backgroundColor: 'white',Color: 'white'}}    
      />
      {!isValid && <p style={{color:'red'}}>Please Select City</p>}
                <i className=""></i> </div>
                  </div>
                  {/* Search Field */}
                  <div className="intro-search-field">
                  <MultiSelect
        options={labcategory.map(e => ({ key:e.id,label: e.lab_cat_title, value: e.id}))}
        optionLabel="selected Lab"
        value={selected}        
        onChange={handleOnChange}  
        showGroupWheel={true}
        labelledBy="Select Lab" 
        placeholder="Select Lab"       
      />
      {!isValid1 && <p style={{color:'red'}}>Please Select Lab Test</p>}
                  </div>
                  {/* Button */}
                  <div className="intro-search-button">
                  <button onClick={labList} type="submit" className="button ripple-effect">Get Test Price</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
    <div className="process">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <p>Process</p>
                        <h2>Appointment Process</h2>
                        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/heading.svg"} loading="lazy" alt="heading" className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-9 col-md-6 col-lg-4 mb-4">
                    <div className="process-box">
                        <div className="process-icon">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/Search.jpg"} alt="images" loading="lazy" className="img-fluid" />
                        </div>
                        <div className="process-text">
                            <h2><Link exact='true' to="/#">Search Best Online Professional</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4 mb-4">
                    <div className="process-box">
                        <div className="process-icon">
                            <img src={process.env.REACT_APP_BASE_URL1 + "/assets/img/view.jpg"} alt="images" loading="lazy" className="img-fluid" />
                        </div>
                        <div className="process-text">
                            <h2><Link exact='true' to="/#">View Professional Profile</Link></h2>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4">
                    <div className="process-box">
                        <div className="process-icon">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/get instant.jpg"} loading="lazy" alt="images" className="img-fluid" />
                        </div>
                        <div className="process-text">
                            <h2><Link exact='true' to="/">Get Instant Doctor Appoinment</Link></h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="services">
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link  exact='true' to="/hospital" className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/services/hospitals.jpg"} alt="Hospitals" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Hospitals ({hospitalcount}+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/specialist"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/services/doctors.jpg"} alt="doctors" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Doctors ({doctorcount}+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/healthpackage"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/medylife-banner-1.png"} loading="lazy" alt="pharmacies" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Health Checks ({healthcount}+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/clinic"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/services/clinics2.png"} alt="clinics" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Clinics ({cliniccount}+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/lab"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/services/laboratories.jpg"} alt="laboratories" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Laboratories ({labcount}+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/airambulance"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/airambulance.png"} alt="surgery-rooms" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Air Ambulance (2600+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/medicose" onClick={props.callfunction}  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/nearby.png"} loading="lazy" alt="surgery-rooms" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Near By Medical (2600+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/insurance"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/health-insurance.png"} loading="lazy" alt="surgery-rooms" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">Health Insurance(6+)</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 services-box">
                    <Link exact='true' to="/healthians"  className="service-card">
                        <div className="service-card-img">
                            <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/HEALTHIANS.png"} alt="surgery-rooms" loading="lazy" className="img-fluid" />
                        </div>
                        <h5 className="service-card-name">HEALTHIANS</h5>
                        <div className="service-card-bg"></div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
    
    <div className="count" style={{backgroundImage: `url("${process.env.REACT_APP_BASE_URL1}/assets/img/count-bg.jpg")`}}>
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-3 counter-block mb-4">
                    <div className="counter-block-one" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="2">10</span><span>k+</span>
                            </div>
                            <h4>Experience Doctors</h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 counter-block mb-4">
                    <div className="counter-block-one" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="4">12</span><span>k+</span>
                            </div>
                            <h4>Satisfied Clients</h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 counter-block mb-4">
                    <div className="counter-block-one" data-wow-delay="400ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="6">13</span><span>k+</span>
                            </div>
                            <h4>Total Calls</h4>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3 counter-block mb-4">
                    <div className="counter-block-one" data-wow-delay="600ms" data-wow-duration="1500ms">
                        <div className="inner-box">
                            <div className="count-outer count-box">
                                <span className="count-text" data-speed="1500" data-stop="30">14</span><span>+</span>
                            </div>
                            <h4>Total Calls</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="doctors">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <p>DOCTORS</p>
                        <h2>Our Specialist Doctors</h2>
                        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/heading.svg"} alt="heading" loading="lazy" className="img-fluid" />
                    </div>
                </div>
            </div>
			
            {typeof category != 'undefined' && category.length > 0 && category && (
			
				 <OwlCarousel  className="owl-carousel" 
                 margin={1}
                 loop
                 autoplay ={true}
                 dots={false}
                 items={3}
                 touchDrag={true}
                 lazyLoad={true}                 
                  responsive={state.responsive}// add this line
                 animateOut={'fadeOut'}
                 animateIn={'flipInX'}>
                   {category.map((item,index) => {
				   const { id,doctor_cat_title,doctor_cat_image, doctor_cat_desc,home_page_show } = item;
                   if(home_page_show === 'yes')
				   return (
				   <div key={index}>
                    <div className="item">
                        <div className="doctor-box">
                            <div className="doctor-image">
                                <Link exact='true' to="/" >
                                    <img src={doctor_cat_image} alt="Doctors" loading="lazy" className="img-fluid" />
                                </Link>
                            </div>
                            <div className="doctor-content">
                                <h3>
                                <Link to="/specialist"  state={{ids: id}}> {doctor_cat_title} </Link>                         
                                 
                                    <p>
                                        {doctor_cat_desc}
                                    </p>
                                </h3>
                            </div>
                        </div>
						 </div>
                    </div>
					);
          })}
					</OwlCarousel> 
                )}
            <div className="more-btn text-center">
                <Link exact='true' to="/specialist"  className="specialist-btn">All Specialist<i className="fa fa-arrow-right"></i></Link>
            </div>    
        </div>
    </div>        
    
    <div className="labs">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <p>Labs</p>
                        <h2>Featured Labs</h2>
                        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/heading.svg"} alt="heading" loading="lazy" className="img-fluid" />
                    </div>
                </div>
            </div>
			{typeof labcategory != 'undefined' && labcategory.length > 0 && labcategory && (
            <div className="row">
			
			{labcategory.map((item,i) => {
				   const {id,lab_cat_title,lab_cat_image,home_page_show} = item;
                   if(home_page_show === 'yes')
				   return (   
                <div className="col-sm-6 col-md-6 col-lg-3 mb-4" key={i}>
                    <div className="labs-box">
                    <Link exact='true' to="/labinfo"  state={{ids: id}}  className=""><img src={lab_cat_image} alt="" className="img-fluid" loading="lazy"/></Link>
                        <div className="labs-content">
                            <h4>{lab_cat_title}</h4>
                            <span><Link exact='true' to="/labinfo"  state={{ids: id}}  className=""><i className="fa fa-arrow-right"></i></Link></span>
                        </div>
                    </div>
                </div>            
         );
          })}
		 
            </div>
			)}
             <div className="more-btn text-center">
                <Link exact='true' to="/lab"  className="specialist-btn">All Lab<i className="fa fa-arrow-right"></i></Link>
            </div> 
        </div>
    </div>            
    
    <div className="pharmacy">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <p>PHARMACY</p>
                        <h2>Digital Pharmacy</h2>
                        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/heading.svg"} alt="heading" loading="lazy" className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-9 col-md-6 col-lg-4">
                    <div className="digital-box">
                        <div className="digital-image">
                            <Link exact='true' to="/productlisting">
                                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/pharmacy1.png"} loading="lazy" alt="" className="img-fluid" />
                            </Link>
                            <div className="digital-title">Order Medicine</div>
                            <div className="order-btn"><Link exact='true' to="/productlisting">Order Now</Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4">
                    <div className="digital-box">
                        <div className="digital-image">
                            <Link exact='true' to="/productlisting">
                                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/pharmacy2.png"} loading="lazy" alt="" className="img-fluid" />
                            </Link>
                            <div className="digital-title">Healthcare Products</div>
                            <div className="order-btn"><Link exact='true' to="/productlisting">Order Now</Link></div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-9 col-md-6 col-lg-4">
                    <div className="digital-box">
                        <div className="digital-image">
                            <Link exact='true' to="/productlisting">
                                <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/pharmacy3.png"} loading="lazy" alt="" className="img-fluid" />
                            </Link>
                            <div className="digital-title">Lab Tests</div>
                            <div className="order-btn"><Link exact='true' to="/productlisting">Order Now</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>        
    
    <div className="package" style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/img/health-checks-bg.jpg)` 
    }}>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-heading text-center">
                        <p>PACKAGE</p>
                        <h2>Health Checks & Lab Tests</h2>
                        <img src={process.env.REACT_APP_BASE_URL1 +"/assets/img/heading.svg"} alt="heading" loading="lazy" className="img-fluid" />
                    </div>
                </div>
            </div>
            
			{typeof health != 'undefined' && health.length > 0 && health && (
                 <OwlCarousel  className="owl-carousel" 
                 margin={1}
                 autoplay={true}
                 dots={false}
                 items={3}
                 touchDrag={true}
                 lazyLoad={true}
                  responsive={state.responsive}// add this line
                 animateOut={'fadeOut'}
                 animateIn={'flipInX'}>
                   {health.map((item,i) => {
				   const { health_id, health_title, health_market_price, health_sell_price,include,discount } = item;
				   return (
				   <div key={i}>
                    <div className="item">
                        <div className="package-bg">
                            <div className="price-heading">
                                <h3>{health_title}</h3>
                                <div className="pricing">
                                    <span className="value">₹{health_sell_price}</span><br/>
                                    <span className="off">₹{health_market_price}</span><br/>
                                    <div className="discount">
                                        <h4>{discount}%</h4>
                                        <span>off</span>
                                    </div>
                                </div>
                                <div className="price-body">
                                    <h6>Includes</h6>
                                    <ul>
									{typeof include != 'undefined' && include.length > 0 && include && (
									<div>
									{include.map((item1,j) => {
                                        if(j < 2)
                                        {
										return (
                                        <li key={j+1}><i className="fa fa-check-circle"></i>{item1}</li>
                                        );
                                        }
										})}
										</div>
										)}
                                    </ul>
                                    <div className="know-btn text-center">
                                    <Link to="/health_details"  state={{ids: health_id}} className="know-more-btn"> <i className="fa fa-plus"></i>Know More</Link>
                                       
                                    </div>   
                                </div>
                            </div>
                        </div>    
                    </div>
                   </div>
				   );
          })}
					</OwlCarousel> 
					 )}
                
            </div>
        </div>
       
    <Footer />
    
    
</Fragment>
);
 
}
export default Home;


