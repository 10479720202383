import React,{ useContext,useEffect,useState } from 'react';
import { Link } from "react-router-dom";
    import { ToastContainer, toast } from 'react-toastify'
    import { CartContext } from '../../context/cart.js'
    import { Header } from './Header';
    import { Footer } from './Footer';
    import 'react-toastify/dist/ReactToastify.css';
    import {  useSelector,useDispatch } from "react-redux";
    import {
      productlistt,productlisting1
    } from "../../features/counter/productSlice";
   export const ViewCart = () => { 
    const { cartItems, addToCart, removeFromCart,removeFromCart1, clearCart, getCartTotal,total1} = useContext(CartContext)
    const dispatch=useDispatch();
    const productlist111 = useSelector(productlistt);
    const [productList, setProductList] = useState([]);
    const status = useSelector((state) => state.product.status);
    const notifyCartCleared = () => toast.error(`Cart cleared!`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff'
        }
      })
      const handleRemoveFromCart = (product) => {
        
        const product_name = productList.find((item) => item.product_id === product.product_id);
        //console.log(product_name);
        removeFromCart(product);
        notifyRemovedFromCart(product_name.product_name);
      };
      const notifyRemovedFromCart = (item) => toast.error(`${item} removed from cart!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'colored',
        style: {
          backgroundColor: '#000',
          color: '#fff',
        }
        });
        useEffect(() => {

          if (status === 'idle') {
            dispatch(productlisting1());
          }
          if(status === 'succeeded')
            {
              //console.log(productlist111);
              setProductList(productlist111);
            }
        }, [status, dispatch]);
    return (
        <>
        <Header />
        <div className="banner">
        <div className="banner-item banner-bg inner_banner" style={{ 
      backgroundImage: `url(${process.env.REACT_APP_BASE_URL1}/assets/img/online-shopping-with-cart-laptops.webp)`,marginTop: `92px`
    }}>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
					<h1 style={{ textAlign: 'center',color: "white"}}>Cart</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section className="h-100 h-custom" style={{ backgroundColor: "#d2c9ff" }}>
    <ToastContainer />
  <div className="container py-5 h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12">
        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: 15 }}
        >
          <div className="card-body p-0">
            <div className="row g-0">
              <div className="col-lg-8">
                <div className="p-5">
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <h1 className="fw-bold mb-0 text-black">Shopping Cart</h1>
                    <h6 className="mb-0 text-muted">{cartItems.length} items</h6>
                  </div>
                  <hr className="my-4" />
                  
                  {typeof productList != 'undefined' && productList.length > 0 && cartItems.map((item,j) =>{ 
                    const product_det = productList.find((product11) => product11.product_id === item.product_id);
                   return (
                  <div key={j} className="row mb-4 d-flex justify-content-between align-items-center">
                    <div className="col-md-2 col-lg-2 col-xl-2">
                      
                      <img
                        src={(typeof(product_det['pi_name'] !==undefined) ? product_det.pi_name:'')}
                        className="img-fluid rounded-3"
                        alt="Cotton T-shirt"
                      />
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-3">
                      <h6 className="text-muted">{product_det.product_name}</h6>
                      <h6 className="text-black mb-0">Cotton T-shirt</h6>
                    </div>
                    <div className="col-md-3 col-lg-3 col-xl-2 d-flex">
                      <button
                        className="btn btn-link px-2"
                        onClick={() => {
                            const cartItem = cartItems.find((product) => product.product_id === product_det.product_id);
                            if (cartItem.quantity === 1) {
                              handleRemoveFromCart(item);
                            } else {
                              removeFromCart(item);
                            }
                          }}
                      >
                        <i className="fa fa-minus" />
                      </button>
                      <input
                        id="form1"
                        min={0}
                        name="quantity"
                        value={item.quantity}
                        type="number"
                        readOnly={true}
                        className="form-control form-control-sm"
                      />
                      <button
                        className="btn btn-link px-2"
                        onClick={() => {
                            addToCart(item.product_id,1)
                          }}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1">
                      <h6 className="mb-0">{product_det.product_price}</h6>
                    </div>
                    <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                      <button  className="text-muted" onClick={() => {
                            const cartItem = cartItems.find((product) => product.product_id === product_det.product_id);
                            if (cartItem.quantity === 1) {
                              handleRemoveFromCart(item.product_id);
                            } else {
                              removeFromCart1(item);
                            }
                          }}>
                        <i className="fa fa-times" />
                      </button>
                    </div>
                  </div>
                  );
                })}
                  <hr className="my-4" />
                  <div className="pt-5">
                    <h6 className="mb-0">
                      <Link to="/productlisting" className="text-body">
                        <i className="fa fa-long-arrow-alt-left me-2" />
                        Back to shop
                      </Link>
                    </h6>
                  </div>
                </div>
              </div>
              {total1 > 0 ?
              <>
              <div className="col-lg-4 bg-grey">
                <div className="p-5">
                  <h3 className="fw-bold mb-5 mt-2 pt-1">Summary</h3>
                  <hr className="my-4" />
                  <div className="d-flex justify-content-between mb-4">
                    <h5 className="text-uppercase">items 3</h5>
                    <h5>€ 132.00</h5>
                  </div>
                  <h5 className="text-uppercase mb-3">Shipping</h5>
                  <div className="mb-4 pb-2">
                    <select className="select">
                      <option value={1}>Standard-Delivery- €5.00</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                      <option value={4}>Four</option>
                    </select>
                  </div>
                  <h5 className="text-uppercase mb-3">Give code</h5>
                  <div className="mb-5">
                    <div className="form-outline">
                      <input
                        type="text"
                        id="form3Examplea2"
                        className="form-control form-control-lg"
                      />
                      <label className="form-label" htmlFor="form3Examplea2">
                        Enter your code
                      </label>
                    </div>
                  </div>
                  
                  <hr className="my-4" />
                  <div className="d-flex justify-content-between mb-5">
                    <h5 className="text-uppercase">Total price</h5>
                    <h5>{getCartTotal()}</h5>
                  </div>
                  <button
                    type="button"
                    className="btn btn-dark btn-block btn-lg"
                    data-mdb-ripple-color="dark" onClick={() => {
                        clearCart()
                        notifyCartCleared()
                      }}
                  >
                    Clear Cart
                  </button>
                  <Link to="/checkout" className="btn btn-dark btn-block btn-lg">CheckOut</Link>
                </div>
              </div>
              </>
              :''}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
    </>
    );
    }