import { Button, Form, Input } from "reactstrap";
import React,{useState,useMemo } from 'react';
import {useParams} from "react-router-dom";
import { Stars } from './Stars';
import { FaStar } from "react-icons/fa";
import { format } from 'date-fns';
const token = JSON.parse(localStorage.getItem('user-info-login'));
//console.log(token);
export const ReviewForm = (props) => {
  //console.log(props.profileDetails.profileDetails.image);
    const [reviews, setReviews] = useState("");
    const [reviewlist, setReviewList] = useState([]);
    const [value, setValue] = useState("");
    const [name, setName] = useState("");
    const [star, setStar] = useState();
    const [message, setMessage] = useState("");
    let params = useParams(); 
    const reviewlist1 = async() => {
      await fetch(`${process.env.REACT_APP_BASE_URL}/reviewlist/${params.id}`)
        .then(response => {
          return response.json()
        })
        .then(data2 => {
        //console.log(data2.data);
        setReviewList(data2.data);
         
        })
    }   
    
    useMemo(() => {		
      reviewlist1();
						
	  }, [])
    let onSubmit = async (e) => {
     let doctor_id=params.id;
      e.preventDefault();
      try {
        let res = await fetch(`${process.env.REACT_APP_BASE_URL}/review`, {
          method: "POST",
          body: JSON.stringify({
            name: name,
            comment: reviews,
            ratings: star,
            doctor_id: doctor_id,          
            
          }),
        });
        let resJson = await res.json();
        //console.log(resJson);
        if (resJson.status === true) {
          setReviews("");
          setName("");
          setStar("");
          setMessage("");
          setMessage("Thank you for your review.");
          reviewlist1();
        } else {
          setMessage("Some error occured");
        }
      } catch (err) {
        console.log(err);
      }
      setValue(reviews + " with " + star + " star ");
      
      
    };
    const dateString = format(
      new Date(),
      "EEEE, 'the' do 'of' LLLL, yyyy"
    );
    return (
      
      <div className="form-container">
         <div className="blog-details-content">
       <div className="comment-box">
        <div className="group-title">
          <h3>Comments</h3>
          </div>
          {reviewlist.map((item) => {
				   const { id,name,comment,ratings } = item;
				   return (
					<div key={id}>
          <div className="comment">
            <figure className="thumb-box">
            {token && token!==undefined && token!== null  ? (
              <img src={token.currentUser.photoURL !== null ? token.currentUser.photoURL : ''} alt="" />
              ) :  <img src='assets/img/download (3).png' alt="" />}
              </figure>
              <div className="comment-inner">
                  <div className="comment">
              <div className="comment-inner">
                <div className="comment-info">
                  <h5>{name}</h5>
                  {[...Array(5)].map((star, i) => {
                                 
                return (                
                  <FaStar
              className="star"
              color={(i < ratings) ? "gold" : "lightgray"}
              size={20} key={i}             
            />           
                        );
                    })}
                  <span className="comment-time">{dateString}</span>
                </div>
                <p>{comment}</p>  
              </div>
            </div>
                                 
                  </div>
                  </div>
                  </div>
                   );
                  })}                           
                                  </div>
                                  </div>
       
        {token && token!==undefined && token!== null  ? (
        <Form onSubmit={onSubmit}>
           <Stars setStar={setStar} />
          <Input
            className="form-control"
            type="text"            
            placeholder="Enter you review"
            value={reviews}
            onChange={(e) => setReviews(e.target.value)}
          required />
          <Input
            className="form-control"
            type="text"             
            placeholder="Enter you name"                     
            value={name}
            onChange={(e) => setName(e.target.value)}
            required />
          <br></br>
          <Button type="submit" className="btn btn-primary">
            Submit
          </Button>
          <div>{value}</div>
          <div className="message contact-address-headline success">{message ? <p>{message}</p> : null}</div>
        </Form>
        ) : ''}
      </div>
    );
};