import "./App.css";
import React,{useEffect, useState } from 'react';
import {AuthProvider} from './components/Pages/AuthContext';
import {onAuthStateChanged} from 'firebase/auth'
import {auth} from './components/Pages/firebase';
import {createBrowserRouter,RouterProvider } from "react-router-dom";
import { Hospital } from "./components/Pages/Hospital";
import { Lab } from "./components/Pages/Lab";
import { Health } from "./components/Pages/Health";
import Home from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import { Register } from "./components/Pages/Register";
import { Login } from "./components/Pages/Login";
import { Dashboard } from "./components/Pages/Dashboard";
import { Specialist } from "./components/Pages/Specialist";
import { HowItWorks } from "./components/Pages/HowItWorks";
import { HealthDetails } from "./components/Pages/HealthDetails";
import { DoctorDetails } from "./components/Pages/DoctorDetails";
import { BlogDetails } from "./components/Pages/BlogDetails";
import { FavoriteDoctor } from "./components/Pages/FavoriteDoctor";
import { Profile } from "./components/Pages/Profile";
import { ChangePassword } from "./components/Pages/ChangePassword";
import { Labs } from "./components/Pages/Labs";
import { LabBooking } from "./components/Pages/LabBooking";
import {Doctor } from "./components/Pages/Doctor";
import {Team } from "./components/Pages/Team";
import {Partner } from "./components/Pages/Partner";
import {Digital } from "./components/Pages/Digital";
import {Clinic } from "./components/Pages/Clinic";
import {LabInfo } from "./components/Pages/LabInfo";
import {HealthPackage } from "./components/Pages/HealthPackage";
import {AirAmbulance } from "./components/Pages/AirAmbulance";
import {Schedule } from "./components/Pages/Schedule";  
import {ProductListing } from "./components/Pages/ProductListing"; 
import {ProductDetails } from "./components/Pages/ProductDetails";
import {ForgetPassword } from "./components/Pages/ForgetPassword";  
import {ViewCart } from "./components/Pages/ViewCart";
import {Error } from "./components/Pages/Error"; 
import PrivateRoute from './PrivateRoute'
import {VerifyEmail } from "./components/Pages/VerifyEmail";  
import {LocationComp } from "./components/Pages/LocationComp";  
import {Medicose } from "./components/Pages/Medicose";
import {CheckOut } from "./components/Pages/CheckOut";
import {CheckOut1 } from "./components/Pages/CheckOut1";
import {Insurance } from "./components/Insurance";
import {OrderDetails } from "./components/Pages/OrderDetails";
import {Healthians } from "./components/Pages/Healthians";
import {Paypal} from "./components/Pages/Paypal";
import {ThankYou} from "./components/Pages/ThankYou";
import NoInternetConnection from "./components/Pages/NoInternetConnection";
import { useDispatch } from 'react-redux';
import{fetcharea,cityname,productlisting1} from "./features/counter/apiSlice";
import 'font-awesome/css/font-awesome.min.css';
import { GetToken, CourierServiceability , Tracking_OrderId } from 'shiprocket-api'
function App() {     
const [currentUser, setCurrentUser] = useState(null)
//const token = JSON.parse(localStorage.getItem('user-info-login')); 
//let params = useParams();
const dispatch=useDispatch();
//console.log(params.id);
//const navigate = useNavigate();  

useEffect(() => {
  dispatch(fetcharea());
 // dispatch(currentlatitude());
  //dispatch(currentlongitude());
  navigator.geolocation.getCurrentPosition(function(position) {   
    var latlong={"latitude":position.coords.latitude,"longitude":position.coords.longitude};
    dispatch(cityname(latlong)); 
  })
}, []);
//console.log(currentUser);
    useEffect(() => {   
      onAuthStateChanged(auth, (user) => {
        setCurrentUser(user)
        
        //console.log(auth);
       })
    }, [])
   
const handleLogin = async() => {
  ///  Get Token
  await GetToken({
    email : 'sandip.midas@gmail.com',
    password: 'Sandeep@123',
  })
  //console.log(token);
  ///  Check Courier Serviceability
   await CourierServiceability({
  
  auth: {
          email : 'sandip.midas@gmail.com',
          password: 'Sandeep@123',
      },
  params: {
      pickup_postcode : 122015,
      delivery_postcode : 284204,
      weight: 2,
      cod : 1
  }
  })
  
  //// Get Tracking Data through Order ID
   await Tracking_OrderId({
      auth: {
          email : 'sandip.midas@gmail.com',
          password: 'Sandeep@123',
      },
      params: {
          order_id : '55097',
      }
  })
  } 
        const router = createBrowserRouter([
          {
            path: "/",
            element:<Home/>,
          },
          {
            path: "/hospital",
            element: <Hospital/>,
          },
          {
            path: "/lab",
            element: <Lab/>,
          },
          {
            path: "/health",
            element: <Health />,
          },
          {
            path: "/register",
            element: <Register/>,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/about",
            element: <About />,
          },
          {
            path: "/contact",
            element: <Contact />,
          },
          {
            path: "/dashboard",
            element: <PrivateRoute Component={Dashboard} />,
          },
          {
            path: "/specialist",
            element: <Specialist/>,
          },
          {
            path: "/how-it-works",
            element: <HowItWorks />,
          },
          {
            path: "/health_details",
            element: <HealthDetails/>,
          },
          {
            path: "/doctor_details",
            element: <DoctorDetails/>,
          },
          {
            path: "/blog",
            element: <Blog />,
          },
          {
            path: "/blog/:id",
            element:<BlogDetails />,
          },
          {
            path: "/specialist/:id",
            element:<DoctorDetails/>,
          },
          {
            path: "/favorite_doctor",
            element:<FavoriteDoctor />,
          },
          {
            path: "/change_password",
            element:<PrivateRoute Component={ChangePassword} />,
          },
          {
            path: "/labs",
            element:<Labs />,
          },
          {
            path: "/labbooking",
            element:<LabBooking />,
          },
          {
            path: "/doctor",
            element:<Doctor/>,
          },
          {
            path: "/team",
            element:<Team />,
          },
          {
            path: "/partner",
            element:<Partner />,
          },
          {
            path: "/digital",
            element:<Digital />,
          },
          {
            path: "/clinic",
            element:<Clinic/>,
          },
          {
            path: "/labinfo",
            element:<LabInfo />,
          },
          {
            path: "/healthpackage",
            element:<HealthPackage />,
          },
          {
            path: "/airambulance",
            element:<AirAmbulance/>,
          },
          {
            path: "/schedule",
            element:<PrivateRoute Component={Schedule} />,
          },
          {
            path: "/productlisting",
            element:<ProductListing />,
          },
          {
            path: "/productlisting",
            element:<ProductListing />,
          },
          {
            path: "/productdetails/:id",
            element:<ProductDetails/>,
          },
          {
            path: "/verifyemail",
            element:<VerifyEmail/>,
          },
          {
            path: "/forgetpassword",
            element:<ForgetPassword/>,
          },
          {
            path: "/location",
            element:<LocationComp/>,
          },
          {
            path: "/medicose",
            element:<Medicose/>,
          },
          {
            path: "/checkout",
            element:<CheckOut/>,
          },
          {
            path: "/checkout1",
            element:<CheckOut1/>,
          },
          {
            path: "/insurance",
            element:<Insurance/>,
          },
          {
            path: "/cart",
            element:<ViewCart/>,
          },
          {
            path: "/order",
            element:<PrivateRoute Component={OrderDetails} />,
          },
          {
            path: "*",
            element:<Error/>,
          },
          {
            path: "/profile",
            element:<PrivateRoute Component={Profile} />,
          },
          {
            path: "/healthians",
            element:<Healthians/>,
          },
          {
            path: "/paypal",
            element:<Paypal/>,
          },
          {
            path: "/thankyou",
            element:<ThankYou/>,
          },
          {
            path: "/counter",
            element:<ThankYou/>,
          },
        ],
        {
        basename:'/' 
        });
        
  return (
    <div className="App">
        <NoInternetConnection></NoInternetConnection>
       <AuthProvider value={{currentUser}}>   
       <RouterProvider router={router} />
            </AuthProvider>           
            </div>
  );
}

export default App;
